#member-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 2rem;

    .server-time {
        p {
            margin: 0;
            font-size: 0.786rem;
        }
    }

    .header-right {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .divider {
            height: 19px;
            width: 1px;
            background-color: #585858;
            margin: 0 30px;
        }

        .sponsor {
            p {
                margin: 0;
                text-transform: capitalize;
            }
        }

        .user_logged_in {
            display: flex;
            align-items: center;
            cursor: pointer;

            .photo {
                img {
                    width: 46px;
                    height: 46px;
                    border-radius: 50%;
                }
            }

            
            .info {
                padding-left: 1rem;

                h4 {
                    font-size: 1rem;
                    font-family: $font-secondary;
                    margin: 0;
                    text-transform: capitalize;
                }

                p {
                    color: #959699;
                    font-size: 0.857rem;
                    margin: 3px 0 0 0;
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    #member-header {
        .server-time {
            display: none;
        }

        .header-right {
            .sponsor {
                display: none;
            }
        }
    }
}