.card-black {
    background-color: #0E0E0E;
    border-radius: $radius;
    
}

.card-title {
    font-weight: 500;
    font-size: 1.286rem;
}

.card-backdrop {
    border-radius: 8px;
    background: #151517;
    backdrop-filter: blur(100px);
}

.card-bottom-shadow {
    position: relative;

    &::before {
        content: "";
        width: 97%;
        box-shadow: 0px 3px 20px #0000000b;
        position: absolute;
        left: 0px;
        right: 0px;
        margin-left: auto;
        margin-right: auto;
        height: 100%;
        border-radius: 8px;
        bottom: -10px;
        background-color: #202020;
    }
}

.card-body {
    position: relative;
}

.card-action-title {
    display: flex;
    justify-content: space-between;

    .card-actions {
        display: flex;
        align-items: center;

        p {
            margin: 0 20px 0 0;
            span {
                margin-right: 6px;
            }
        }

    }
}