.slick-slider {
    position: inherit;
    display: block;
    box-sizing: border-box;
    -webkit-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
    padding: 0;
}

.slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-evenly;
    .slick-track {
        margin: 0;
    }
}



.slick-slider .slick-track,
.slick-slider .slick-list {
    transform: translate3d(0, 0, 0);
}

.slick-dots {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 15;
    justify-content: center;
    margin: -8px auto 0;
    bottom: unset;

    &.slick-dots-bottom {
        bottom: 50px;
    }

    li {
        position: relative;
        display: inline-block;
        width: 10px;
        height: 10px;
        margin: 0 5px; /* Add margin between the dots */
        padding: 0;
        cursor: pointer;
        border-radius: 50%;

        button {
            display: block;
            width: 100%;
            height: 8px;
            padding: 0px;
            color: transparent;
            font-size: 12px;
            background-color: rgb(234, 236, 239) !important;
            border: 0px;
            border-radius: 8px;
            outline: none;
            cursor: pointer;
            transition: all 0.5s ease 0s;
        }

        &.slick-active {
            button {
                background-color:$primary !important;
                background: $primary !important;
            }
        }
    }
}


@keyframes bounce {

    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(-10px);
    }

    60% {
        transform: translateY(-5px);
    }
}



.slick-list {
    min-height: 468px;
}

.centered-carousel {
    display: flex;
    justify-content: center;
    align-items: center;

    .center-slide {
        display: flex !important;
        justify-content: center;
        align-items: center;
        // background: #364d79;
    }
}