.modal-footer-buttons {
    text-align: right;

    button {
        margin-left: 10px;
    }
}
.modal-theme {

    &.size-md {
        .modal-dialog {
            max-width: 500px;
        }
    }

    .modal-content {
        padding: 2px;
    }

    .modal-header {
        background-color: #212529;
        padding: 14px 30px;
        position: relative;

        &.header-dark {
            margin: -2px;

            .modal-title {
                color: #FFF;
            }

            .btn-close {
                filter: invert(100%);
            }
        }

        &::after {
            content: "";
            display: block;
            width: 100%;
            height: 2px;
            position: absolute;
            bottom: 0;
            left: 0;
        }

        .modal-title {
            font-size: 18px;
            font-weight: bold;
            color: #000;
        }
    }

    &.modal-small {
        .modal-dialog {
            max-width: 550px;
        }
    }


    &.modal-lg {
        .modal-dialog {
            max-width: 960px;

        }
    }

  


    .modal-dialog {
        max-width: 800px;

    }

    .modal-body {
        padding: 2.2rem 2rem;
        background-color: #212529;
        img{
            width: 100%;
            border-radius: $radius;
        }
    }
    
}
@media screen and (max-width:767px) {
    .bg-img-modal {
        .modal-header {
    
            .title {
                bottom: 2.5%;
            }
        }
    }
    .modal-theme {
        .modal-body {
            padding: 1.3rem;
        }

        &.modal-thumbnail {
            .modal-dialog {
                iframe {
                    height: 245px !important;
                }
            }
        }
    }
}