*,html {
	box-sizing: border-box;
	//font-size: 14px;
}

body {
	font-family: $font-primary;
	background-color: $secondary;
	margin: 0;
	padding: 0;
	color: #FFF;
}

a {
	color: $primary;
	text-decoration: none;

	&:hover,
	&:focus,
	&:active {
		color: $primary;
		text-decoration: underline;
	}
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: $font-secondary;
}

.skiptranslate {
	display: none;
}

img {
	max-width: 100%;
}


ul.ant-menu {
    border-radius: 8px;
    border: none;
}

.ant-checkbox .ant-checkbox-inner:after {
	border-color: #000 !important;
}

.ant-menu-title-content {
	a {
		&:hover {
			text-decoration: none;
		}
	}
}

@media screen and (max-width: 767px) {

	
	body,
	html,
	* {
		font-size: 14px;
	}
}