.ref-table {
    width: 100%;
    border-collapse: collapse;
    text-align: left;

    thead {
        th {
            color: rgba(255,255,255,.6);
            padding-bottom: 10px;
        }
    }

    .ref-member {
        display: flex;
        align-items: center;
        img {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            margin-right: 10px;
        }
        p {
            margin: 0;
        }
    }

    tbody {
        tr {
            border-top: 1px solid rgba(255,255,255,.1);
        }
        td {
            padding: 8px 4px;
        }
    }
}