.button {
	display: inline-block;
	vertical-align: middle;
	border: none;
	text-align: center;
	transition: all 0.4s ease-in-out;
	border: 1px solid transparent;
	font-size: 14px;
	font-weight: 600;
	padding: 8px 10px;
	border-radius: 5px;
	color: $primary;
	cursor: pointer;

	&.hover-on-dark {
		&:hover {
			color: $secondary;
			border-color: $litegray;
		}
	}

	.button-small {
		font-size: 0.929rem;
	}
}


.button-long {
	min-width: 130px;
}

.button-dark {
	background-color: $black;
	color: #FFF;

	&:hover {
		background-color: transparent;
		border-color: $black;
		color: $black;
	}
}

.button-icon {
	position: relative;
	display: inline-flex;
	justify-content: space-between;
	padding: 8px 0px 8px 10px;
	white-space: nowrap;
	align-items: center;


	span {
		width: 30px;
		height: auto;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 14px;
		font-weight: 700;
	}

	&.icon-right {
		span {
			margin-left: 8px;
		}
	}

	&.icon-left {
		flex-direction: row-reverse;
		justify-content: center;
		padding: 0.6rem 1rem 0.6rem 1rem;

		span,
		i {
			margin-left: 0;
			margin-right: 0.4rem;
		}
	}

	&.button-dark {
		span {
			background-color: $primary;
			color: $black;
		}
	}
}

.button-white {
	background-color: rgb(255, 255, 255);
	box-shadow: 0 3px 20px #0000000b;
	color: $primary;

	&:hover {
		background-color: #f1f1f1;
	}
}

.btn:focus {
	outline: none;
	box-shadow: none;
}

.button-primary {
	background-color: $primary;
	border-radius: 80px;
	color: #142129 !important;
	position: relative;
	padding: 6px 30px;
	font-size: 16px;
	text-align: center;
	font-weight: bold;
	text-transform: uppercase;
	border: 3px solid rgba(255, 255, 255, 0.5);
	box-shadow: -1px -1px 11px 2px rgba(255, 190, 24, 0.51);
	-webkit-box-shadow: -1px -1px 11px 2px rgba(255, 190, 24, 0.51);
	-moz-box-shadow: -1px -1px 11px 2px rgba(255, 190, 24, 0.51);
	overflow: hidden;
	transition: all 0.4s ease;
	cursor: pointer;

	&:hover,
	&:focus {
		color: #142129 !important;
		// transform: scale(1.1);
		opacity: .8;
	}

	&:disabled {
		opacity: .5;
		cursor: none;
	}

	&::after {
		content: "";
		width: 100%;
		position: absolute;
		height: 100%;
		bottom: -24px;
		left: 0;
		background-color: rgba(0, 0, 0, 0.15);

	}
}

.button-secondary {
	background-color: $secondary;
	color: $white;
	font-weight: 600;
	border: 1px solid $secondary;
	border-color: #707070;

	span {
		font-size: .9rem;
	}

	&:hover {
		background-color: $secondary;
		color: $primary;
		opacity: 1;
	}
}

.dropdown-button {
	&::after {
		border: none !important;
	}
}

.button-outline-secondary {
	border-color: $secondary;
	color: $secondary;

	&:hover {
		color: #FFF;
		background-color: $secondary;
		border: 1px solid $secondary;
	}
}


.button-outline-primary {
	background-color: transparent;
	border-radius: 80px;
	color: $primary;
	position: relative;
	padding: 6px 30px;
	font-size: 16px;
	text-align: center;
	font-weight: bold;
	text-transform: uppercase;
	border: 3px solid rgba(255, 190, 24, 0.5);
	box-shadow: -1px -1px 11px 2px rgba(255, 190, 24, 0.51);
	-webkit-box-shadow: -1px -1px 11px 2px rgba(255, 190, 24, 0.51);
	-moz-box-shadow: -1px -1px 11px 2px rgba(255, 190, 24, 0.51);
	overflow: hidden;
	transition: all 0.4s ease;
	cursor: pointer;

	&:hover,
	&:focus {
		color: #ffffff;
		// transform: scale(1.1);
		opacity: .8;
	}

	&:disabled {
		opacity: .5;
		cursor: none;
	}

	&::after {
		content: "";
		width: 100%;
		position: absolute;
		height: 100%;
		bottom: -24px;
		left: 0;
		background-color: rgba(0, 0, 0, 0.15);

	}
}



.button-fb {
	background-color: #3C5A9A;
	color: #FFF;

	font-weight: bold;

	&:hover {
		background-color: transparent;
		border-color: #3C5A9A;
		color: #3C5A9A;
		opacity: 1;
	}
}

.button-success {
	background-color: $secondary;
	color: #FFF;

	&:hover {
		color: #FFF;
		opacity: .8;
	}
}




.button-round {
	border-radius: 80px;
}


.button-block {
	width: 100%;
	display: block;
}

.button-outline-gray {
	background-color: #F5F5F5;
	border: 1px solid #707070;
	color: #292724;
	font-weight: bold;

	&:hover {
		color: #292724;
		background-color: #FFF;
	}
}



.button-large-icon {
	display: flex;
	flex-direction: column;
	line-height: 3rem;

	.icon {
		font-size: 3rem;
	}

	.label {
		font-size: 1.5rem;
	}
}

// .ant-btn-primary {
// 	background-color: $primary;
// 	color: $black;

// 	&:hover,
// 	&:focus {
// 		background-color: $primary !important;
// 		opacity: .9;
// 		color: $black !important;
// 	}
// }


.round-icon-button {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	&:hover {
		text-decoration: none;
	}

	.icon {
		width: 42px;
		height: 42px;
		border: 1px solid #FFF;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.label {
		font-size: 0.929rem;
		color: #FFF;
		margin-top: 6px;

	}
}

.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
	color: #000 !important;
}