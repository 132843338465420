.pagination-wrap {
    margin-top: 2rem;
}

.pagination {
    --bs-pagination-bg: #ffffff;
    --bs-pagination-color: $secondary;
    --bs-pagination-border-width: 1px;
    --bs-pagination-border-color: transparant;
    --bs-pagination-hover-color: $secondary;
    --bs-pagination-hover-bg: #e9ecef;
    --bs-pagination-hover-border-color: transparant;
    --bs-pagination-focus-color: white;
    --bs-pagination-focus-bg: $primary;
    --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(175, 249, 4, 0.2);
    --bs-pagination-active-color: #000000;
    --bs-pagination-active-bg: rgb(255, 255, 255);
    --bs-pagination-active-border-color: $primary;
    --bs-pagination-disabled-color: #fff;
    --bs-pagination-disabled-bg: $primary;
    --bs-pagination-disabled-border-color: $primary;

    .next {
        padding: 6px 24px;
    }
}

.page-item {
    &.active {
        .page-link {
            background-color: $primary;
            border-color: $primary;
            color: $secondary;

            &:focus,
            &:active,
            &:hover {
                background: $secondary;
                color: $primary;
                box-shadow: 0 0 5px 1px rgba(255, 190, 24, 0.6) !important;
            }
        }
    }

    &.disabled {
        .page-link {
            background-color: $secondary;
            border: 1px solid $primary;
            color: $primary;
        }
    }

    .page-link {
        border-radius: $radius;
        margin: 3px;
        width: 30px;
        height: 30px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        margin: 10px;
        background-color: $secondary;
        color: $primary;
        border: #000000;

        &:focus,
        &:active,
        &:hover {
            background: $secondary;
            color: $primary;
            box-shadow: 0 0 5px 1px rgba(255, 190, 24, 0.6) !important;
        }
    }

    &:hover {
        color: $secondary;
    }


    &:last-child,
    &:first-child {
        .page-link {
            border-radius: $radius;
            background: $secondary;
            color: $primary;
            margin-left: 0px;
        }
    }
}