.simple-section {
    padding: 70px 10px;
}

.section-title {

    h3 {
        font-weight: 700;
        font-size: 35px;
        margin: 0;

        span {
            color: $primary;
        }

        &.primary {
            color: $primary;
        }

        &.secondary {
            color: $secondary;
        }

        &.white {
            color: $white;
        }
    }

    h4 {
        font-weight: 700;
        font-size: 39px;
        margin: 0;
        line-height: 60px;

        span {
            color: $primary;
        }

        &.primary {
            color: $primary;
        }

        &.secondary {
            color: $secondary;
        }

        &.white {
            color: $white;
        }
    }

}

.subtitle {
    font-weight: 500;
    font-size: 1rem;
    line-height: 134.02%;
    color: $white;

    span {
        font-weight: 500;
        color: $primary;
    }
}

.section-detail {
    padding-top: 10px;
    font-size: 1rem;
    color: $litegray;

    span {
        color: $primary;
    }

    &.white {
        color: $white;
    }
}

.tg-container {
    max-width: 1280px;
    margin: auto;
}

.image-fluid {
    width: 100%;
    height: auto;
}

.text-primary {
    color: $primary !important;
}

.text-white {
    color: $white;
}

.bg-black {
    background-color: $black;
}

.text-dim-6 {
    color: rgba(255, 255, 255, .6);
}

.position-relative {
    position: relative;
}

.text-center {
    text-align: center;
}

.text-end {
    text-align: end;
}

.upload-box {
    border: 1px dashed $litegray;
    padding: 30px;
    text-align: center;
    border-radius: 5px;
    margin-top: 10px;

    &:hover {
        border: 1px dashed $primary;
    }
}

.check_list {
    list-style: none;
    padding: 0;
    margin-top: 10px;

    li {
        display: flex;
        align-items: center;
        margin-bottom: 15px;

        span {
            color: $primary;
            margin-right: 10px;
        }
    }

}

.count-list {
    list-style-type: none;
    padding: 0;

    li {
        display: flex;
        align-items: flex-start;
        padding: 1em 0;

        span {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            background-color: $primary;
            color: $black;
            font-weight: 700;
            font-size: 1rem;
            min-width: 30px;
            height: 30px;
            border-radius: 50%;
        }

        .content {
            margin-left: 1em;

            h5 {
                font-size: 1.2rem;
                margin: 0;
            }
        }
    }

}


.tabs-menu {
    margin: 0 0 1rem 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    justify-content: center;

    li {
        a {
            text-transform: uppercase;
            display: inline-block;
            background-color: #202020;
            color: #FFF;
            padding: 8px 20px;
            border-radius: 4px;
            text-align: center;
            margin: 10px;

            &.current {
                color: $primary;
            }
        }
    }
}


.center-info {
    text-align: center;

    span {
        color: $primary;
        font-weight: 700;
        font-size: 2rem;
        width: 80px;
        height: 80px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        background-color: black;
        border: 1px solid $primary;
        border-radius: 50%;
        flex-shrink: 0;
        margin-bottom: .3em;
    }

    h3 {
        font-size: 1.2rem;
        color: $white;
        margin-bottom: 0;
    }
}

@media screen and (max-width:767px) {
    .simple-section {
        padding: 20px;
    }

    .page-head {

        min-height: 120px;
        background-size: cover;
        background-position: right;
        background-repeat: no-repeat;

        h3 {
            font-size: 1.2rem;
            font-weight: 600;
        }
    }

    .section-title {

        h3 {
            font-size: 27px;

            span {
                font-size: 27px;
            }
        }

        h4 {
            font-size: 20px;
            line-height: normal;

            span {
                font-size: 20px;
            }
        }

    }

    .ant-table-content {
        overflow-x: scroll !important;

        table {
            white-space: nowrap;
            word-wrap: break-word;
        }
    }
}