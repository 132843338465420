.ant-form-item-label {
    > label {
        //color: #FFF !important;
    }
}

.ant-form-vertical {
    .ant-form-item-label {
        padding: 0 !important;
    }
}

.forgot-password {
    text-align: right;
    margin-bottom: 1rem;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: #FFF !important;
}

.icon-input {
    position: relative;

    .icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 16px;

        svg {
            width: 20px;
            cursor: pointer;
            color: $primary;
        }
    }

    .icon-right {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 16px;

        svg {
            width: 20px;
            cursor: pointer;
            color: $primary;
        }
    }

    .toggle-password {
        position: absolute;
        right: 14px;
        top: 16px;

        svg {
            width: 26px;
            color: rgba(255, 255, 255, .5);
        }
    }


    .form-control {
        padding-left: 52px;
    }
}

.form-default {
    label {
        color: #fff;
        margin-bottom: 10px;
    }

    .form-control {
        min-height: 60px;
        background-color: #161E26;
        border: 1px solid rgba(255, 255, 255, .2);
        color: #FFF;
    }

    .form-select {
        min-height: 60px;
        background-color: #161E26;
        border: 1px solid rgba(255, 255, 255, .2);
        color: #FFF;
    }
}