#admin-body {
  height: 100vh;
  overflow: hidden;

  a {
    text-decoration: none;

  }
}

.admin-title {
  background-color: $secondary;
  padding: 1rem 0;
  color: #fff;

  h4 {
    margin: 0 0 0 20px;
    text-transform: uppercase;
  }

  h3 {
    margin: 0;
    text-transform: uppercase;
  }
}

.title-bar {
  background-color: #111925;
  padding: 2px 0;
  margin-bottom: 1rem;
  display: none;

  h4 {
    color: #fff;
    margin: 0;
    font-size: 18px;
    font-weight: normal;
  }
}

.admin-card {
  border-radius: 10px;
  color: $white;
  margin-bottom: 25px;
  // font-family: $poppin;
  transition: all 0.4s ease-in-out;

  .card-inner {

    border-radius: 10px;
    color: #142129;
    position: relative;
    font-size: 16px;
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
    border: 3px solid rgba(255, 255, 255, 0.5);
    box-shadow: -1px -1px 11px 2px rgba(255, 190, 24, 0.51);
    -webkit-box-shadow: -1px -1px 11px 2px rgba(255, 190, 24, 0.51);
    -moz-box-shadow: -1px -1px 11px 2px rgba(255, 190, 24, 0.51);
    overflow: hidden;
    transition: all 0.4s ease;
    cursor: pointer;
    padding: 16px 0;
    gap: 10px;
    width: 100%;

    .icon {
      padding-top: 10px;

      img {
        width: 60px;
        transition: all 0.5s ease;
      }
    }

    &:hover {
      .icon {
        img {
          transform: scale(1.2);
        }
      }
    }

    .value {
      min-width: 100%;
      position: relative;
      min-height: 39px;
      padding: 9px 20px;

      background: $primary;
      text-align: center;

      &::after {
        content: "";
        width: 100%;
        position: absolute;
        height: 50%;
        bottom: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.15);

      }
    }

    h2 {
      // font-family: $font-number;
      font-weight: 600;
      margin: 0;
      font-size: 1.1rem;
      border-radius: 0px;
      color: #000000;



    }

    p {
      font-size: 1.1rem;
      text-align: center;
      text-transform: capitalize !important;
      color: #fff;
      margin: 10px 0;
      // font-family: $poppin;
    }

    h6 {
      color: $black;
      font-weight: 400;
      position: absolute;
      top: 25px;
      right: 16px;
      transform: translate(0%, -50%);
    }

    &.blue,
    &.red,
    &.green,
    &.brown {
      background: #000000;

    }

    &::after {
      content: "";
      width: 100%;
      position: absolute;
      height: 50%;
      top: 0;
      left: 0;
      background-color: rgb(124 124 124 / 15%);
    }
  }
}

.header-admin {
  border-bottom: 1px solid rgb(255 255 255 / 49%);
  padding: 20px 0;

  .navbar-brand {
    img {
      width: 150px !important;
    }
  }

  .navbar-nav {
    .nav-link {
      padding: 10px 15px;
      font-size: 15px;
    }
  }

  .admin_header_right {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    li {
      margin-left: 20px;

      a {
        color: $primary;
      }

      .admin_logged_in_user {
        display: flex;
        align-items: center;

        span {
          width: 40px;
          height: 40px;
          background-color: $primary;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 20px;
          color: #000000;
          margin-right: 10px;
        }

        h4 {
          margin: 0;
          font-size: 16px;
          color: #fff;
        }

        p {
          margin: 0;
          font-size: 13px;
          color: #666;
        }
      }
    }
  }
}

.admin-navbar {
  display: none !important;
}

.admin_login_wrap {
  // background-image: url("../images/admin/bg-body.png");
  background-size: cover;
  background-attachment: fixed;
  min-height: 100vh;
  background-color: rgb(243, 244, 246);
  display: flex;
  align-items: center;
  justify-content: center;

  .login_box {
    width: 450px;
    margin: auto;

    .login_box_head {
      background-color: #111925;
      border-radius: 6px 6px 0 0;
      padding: 20px;
      text-align: center;

      img {
        width: 80px;
      }

      h4 {
        margin: 20px 0 0 0;
        font-size: 20px;
        color: #fff;
      }

      p {
        font-size: 14px;
        color: rgba(255, 255, 255, 0.7);
        font-weight: 300;
        margin-bottom: 0;
      }
    }

    .login_box_body {
      background-color: #333;
      border-radius: 0 0 6px 6px;
      padding: 40px;
      border: 1px solid #e5e7eb;

      input {
        color: #000;
      }
    }
  }
}

.verify {
  display: flex;
  justify-content: space-between;
  gap: 10px;

  .Character {
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    background-color: $primary;
    width: 40%;
    min-height: 40px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    justify-content: center;
    color: #000000;
    text-shadow: 1px solid black;
    letter-spacing: 0.6rem;
    font-style: italic;
    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
  }

  .form-group {
    width: 60%;
  }
}

.admin_card_accordian {
  background-color: transparent;
  border: none;

  .accordion-item {
    border: none;
    background-color: transparent;

    .accordion-header {
      .accordion-button {
        background: rgb(86, 83, 61);
        background: linear-gradient(90deg,
            rgba(86, 83, 61, 1) 0%,
            rgba(17, 25, 37, 1) 100%);
        padding: 1.5rem;
        color: #fff;
        font-weight: 400;
        font-size: 20px;

        &:focus {
          box-shadow: none;
        }

        &::after {
          background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23FFFFFF%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
        }
      }

      .accordion-button:not(.collapsed)::after {
        background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23FFFFFF%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
      }
    }

    .accordion-body {
      background-color: transparent;
      border: none;
      padding: 20px 0;
    }
  }
}

.admin-content {
  .nav-pills {
    margin-top: 1rem;

    .nav-item {
      .nav-link {
        color: rgba(255, 255, 255, 0.7);

        &.active {
          background-color: $primary;
          color: #000;
        }
      }
    }
  }
}

.header-admin {
  background-color: #000;

}


.input-icon {
  position: relative;

  .icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 16px;
    cursor: pointer;

    svg {
      width: 20px;
      cursor: pointer;
      color: $primary;
    }
  }

  .icon-right {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 16px;

    svg {
      width: 20px;
      cursor: pointer;
      color: $primary;
    }
  }
}

.inline-action {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style-type: none;
  justify-content: center;

  li {
    &:last-child {
      a {
        border-right: none;
      }
    }

    a {
      text-transform: uppercase;
      font-size: 13px;
      color: $primary;
      display: inline-block;
      border-right: 1px solid rgba(255, 255, 255, 0.7);
      padding: 0 8px;
    }
  }
}

.admin-wrap {
  display: flex;

  .admin-left {
    // width: 18% !important;
    width: 15.5%;
    background-color: #111925;
    height: 100vh;
    overflow-y: scroll;

    &:hover {
      &::-webkit-scrollbar-thumb {
        display: block;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
      }
    }

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: darkgrey;
      border-radius: 50px;
      display: none;
    }

    .admin_side_nav {
      margin: 0;
      padding: 15px 11px;
      list-style-type: none;

      li {
        &:first-child {


          a {
            border: none;
            box-shadow: none;
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            overflow: hidden;
            transition: all 0.4s ease;
            background-color: transparent;
            text-decoration: none;
            border-radius: 5px;
            height: 125px;
            padding: 0;
            position: relative;

            img {
              width: 150px;
            }

            &:hover {
              background-color: transparent;
            }

            &::after {
              display: none;
            }

            &:hover,
            &.active,
            &:focus {
              border: none;
              box-shadow: none;
              -webkit-box-shadow: none;
              -moz-box-shadow: none;
              background-color: transparent;
            }
          }
        }

        a {
          font-family: $font-secondary;
          font-size: 13px;
          color: rgba(255, 255, 255, 0.7);
          padding: 6px 16px;
          display: flex;
          align-items: center;
          border-radius: 50px;
          position: relative;
          border: 3px solid transparent;

          &::after {
            content: "";
            width: 100%;
            position: absolute;
            height: 100%;
            bottom: -24px;
            left: 0;
            background-color: rgba(0, 0, 0, 0.15);
            opacity: 0;
          }

          &:hover,
          &.active,
          &:focus {
            border: 3px solid rgba(255, 255, 255, 0.5);
            box-shadow: -1px -1px 11px 2px rgba(255, 190, 24, 0.51);
            -webkit-box-shadow: -1px -1px 11px 2px rgba(255, 190, 24, 0.51);
            -moz-box-shadow: -1px -1px 11px 2px rgba(255, 190, 24, 0.51);
            overflow: hidden;
            transition: all 0.4s ease;
            background-color: $primary;
            color: #000;
            text-decoration: none;

            &::after {
              opacity: 1;
            }
          }

          i {
            line-height: unset;
            margin-right: 10px;
          }
        }
      }
    }
  }

  .admin-right {
    width: calc(100% - 15.5%) !important;
    background-color: #000000;

    >.admin-content {
      overflow-y: scroll;
      height: calc(100vh - 69px);
      padding: 30px 30px 150px 30px;
    }
  }
}

.form-group {

  .form-control {
    min-height: 60px;
    background-color: #161E26;
    border: 1px solid rgba(255, 255, 255, .2);
    color: #FFF;
  }

  .form-select {
    min-height: 60px;
    background-color: #161E26;
    border: 1px solid rgba(255, 255, 255, .2);
    color: #FFF;
  }

  // .form-control,
  // .form-select {

  //   &:active,
  //   &:focus,
  //   &:target,
  //   &:focus-visible {
  //     border-color: rgba(255, 190, 24, 0.6);
  //     border-color: rgba(255, 190, 24, 0.6);
  //     border: 1px solid rgba(255, 190, 24, 0.6);
  //     box-shadow: 0 0 5px 1px rgba(255, 190, 24, 0.6) !important;
  //   }
  // }

  input,
  select {

    &:active,
    &:focus,
    &:target,
    &:focus-visible {
      box-shadow: none !important;
      outline: none !important;
      border: none;
      border-color: transparent !important;
    }
  }
}

.form_admin {

  .card {
    background-color: $secondary;
    border: transparent;
  }

  .form-group {
    position: relative;

    label {
      color: $white;
      font-weight: 500;
      font-size: 13px;
      margin-bottom: 3px;
    }

    .form-control,
    .form-select {
      border: 1px solid rgb(255, 255, 255, .28);
      border-radius: 8px;
      background-color: black;
      min-height: 36px;

      &:active,
      &:focus,
      &:target,
      &:focus-visible {
        border-color: rgba(255, 190, 24, 0.6);
        border: 1px solid rgba(255, 190, 24, 0.6);
        box-shadow: 0 0 5px 1px rgba(255, 190, 24, 0.6) !important;
      }

      &::placeholder {
        color: rgb(255, 255, 255);
      }

      option:hover {
        // Add this selector for option hover
        background-color: #00FF00; // Change the background color to green on hover
      }

      &.disabled {
        background-color: #000000;
        border: 1px solid rgb(255, 255, 255, .28);
        opacity: 1;

        &::placeholder {
          color: black;
        }
      }
    }

    input,
    select {

      &:active,
      &:focus,
      &:target,
      &:focus-visible {
        box-shadow: none !important;
        outline: none !important;
        border: none;
        border-color: transparent !important;
      }
    }
  }
}

.user-card {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  flex-wrap: wrap;
  align-items: center;

  .front,
  .back {
    width: 48%;
    height: auto;
    border: 1px solid #ccc;
    display: flex;
    margin: auto;
    border-radius: 8px;

    img {
      width: 100%;
      height: auto;
      display: block;
      border-radius: 8px;

    }
  }


}

.progress,
.progress-stacked {
  .progress-bar {

    background-color: $primary;
  }
}

.admin-inputwrap {
  background: rgb(180, 166, 102);
  background: linear-gradient(80deg,
      rgb(190, 169, 75) 0%,
      rgb(143, 140, 114) 50%,
      rgba(255, 255, 255, 0) 100%);
  padding: 2px;
  border-radius: 12px;

  .inner {
    background-color: #ffffff;
    border-radius: 10px;
    overflow: hidden;
    height: 40px;
    padding: 8px;
  }
}

.admin-card-title {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 4px;
  color: $primary;
  // text-transform: uppercase;

  span {
    color: #ffffff;
  }
}

@media screen and (max-width: 767px) {
  .user-card {

    .front,
    .back {
      width: 100%;
    }
  }

  .admin-card-title {
    font-size: 16px;

    span {
      font-size: 16px;
    }
  }

  .admin-navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    z-index: 10;
    background-color: white;

    .admin_logged_in_user {
      display: flex;
      align-items: center;

      span {
        width: 40px;
        height: 40px;
        background-color: $primary;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        color: #fff;
        margin-right: 10px;
      }

      h4 {
        margin: 0;
        font-size: 16px;
        color: #000;
      }

      p {
        margin: 0;
        font-size: 13px;
        color: #666;
      }
    }
  }

  .admin-wrap {
    display: flex;

    .admin-left {
      width: 280px;
      position: absolute;
      transform: translateX(-100%);
      transition: all 0.5s ease;
      z-index: 9;

      &.active {
        transform: translateX(0);
      }

      .admin_side_nav {
        li {
          a {
            font-size: 22px;

            i {
              font-size: 18px;
            }
          }
        }
      }
    }

    .admin-right {
      width: 100% !important;
    }
  }
}