@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap");
.simple-section {
  padding: 70px 10px;
}

.section-title h3 {
  font-weight: 700;
  font-size: 35px;
  margin: 0;
}
.section-title h3 span {
  color: #FFBE18;
}
.section-title h3.primary {
  color: #FFBE18;
}
.section-title h3.secondary {
  color: #181818;
}
.section-title h3.white {
  color: #ffffff;
}
.section-title h4 {
  font-weight: 700;
  font-size: 39px;
  margin: 0;
  line-height: 60px;
}
.section-title h4 span {
  color: #FFBE18;
}
.section-title h4.primary {
  color: #FFBE18;
}
.section-title h4.secondary {
  color: #181818;
}
.section-title h4.white {
  color: #ffffff;
}

.subtitle {
  font-weight: 500;
  font-size: 1rem;
  line-height: 134.02%;
  color: #ffffff;
}
.subtitle span {
  font-weight: 500;
  color: #FFBE18;
}

.section-detail {
  padding-top: 10px;
  font-size: 1rem;
  color: #8a95a3;
}
.section-detail span {
  color: #FFBE18;
}
.section-detail.white {
  color: #ffffff;
}

.tg-container {
  max-width: 1280px;
  margin: auto;
}

.image-fluid {
  width: 100%;
  height: auto;
}

.text-primary {
  color: #FFBE18 !important;
}

.text-white {
  color: #ffffff;
}

.bg-black {
  background-color: #000000;
}

.text-dim-6 {
  color: rgba(255, 255, 255, 0.6);
}

.position-relative {
  position: relative;
}

.text-center {
  text-align: center;
}

.text-end {
  text-align: end;
}

.upload-box {
  border: 1px dashed #8a95a3;
  padding: 30px;
  text-align: center;
  border-radius: 5px;
  margin-top: 10px;
}
.upload-box:hover {
  border: 1px dashed #FFBE18;
}

.check_list {
  list-style: none;
  padding: 0;
  margin-top: 10px;
}
.check_list li {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.check_list li span {
  color: #FFBE18;
  margin-right: 10px;
}

.count-list {
  list-style-type: none;
  padding: 0;
}
.count-list li {
  display: flex;
  align-items: flex-start;
  padding: 1em 0;
}
.count-list li span {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #FFBE18;
  color: #000000;
  font-weight: 700;
  font-size: 1rem;
  min-width: 30px;
  height: 30px;
  border-radius: 50%;
}
.count-list li .content {
  margin-left: 1em;
}
.count-list li .content h5 {
  font-size: 1.2rem;
  margin: 0;
}

.tabs-menu {
  margin: 0 0 1rem 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  justify-content: center;
}
.tabs-menu li a {
  text-transform: uppercase;
  display: inline-block;
  background-color: #202020;
  color: #FFF;
  padding: 8px 20px;
  border-radius: 4px;
  text-align: center;
  margin: 10px;
}
.tabs-menu li a.current {
  color: #FFBE18;
}

.center-info {
  text-align: center;
}
.center-info span {
  color: #FFBE18;
  font-weight: 700;
  font-size: 2rem;
  width: 80px;
  height: 80px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: black;
  border: 1px solid #FFBE18;
  border-radius: 50%;
  flex-shrink: 0;
  margin-bottom: 0.3em;
}
.center-info h3 {
  font-size: 1.2rem;
  color: #ffffff;
  margin-bottom: 0;
}

@media screen and (max-width: 767px) {
  .simple-section {
    padding: 20px;
  }
  .page-head {
    min-height: 120px;
    background-size: cover;
    background-position: right;
    background-repeat: no-repeat;
  }
  .page-head h3 {
    font-size: 1.2rem;
    font-weight: 600;
  }
  .section-title h3 {
    font-size: 27px;
  }
  .section-title h3 span {
    font-size: 27px;
  }
  .section-title h4 {
    font-size: 20px;
    line-height: normal;
  }
  .section-title h4 span {
    font-size: 20px;
  }
  .ant-table-content {
    overflow-x: scroll !important;
  }
  .ant-table-content table {
    white-space: nowrap;
    word-wrap: break-word;
  }
}
.p-0 {
  padding: 0 !important;
}

@media (min-width: 576px) {
  .p-sm-0 {
    padding: 0 !important;
  }
}
@media (min-width: 768px) {
  .p-md-0 {
    padding: 0 !important;
  }
}
.p-1 {
  padding: 0.25rem !important;
}

@media (min-width: 576px) {
  .p-sm-1 {
    padding: 0.25rem !important;
  }
}
@media (min-width: 768px) {
  .p-md-1 {
    padding: 0.25rem !important;
  }
}
.p-2 {
  padding: 0.5rem !important;
}

@media (min-width: 576px) {
  .p-sm-2 {
    padding: 0.5rem !important;
  }
}
@media (min-width: 768px) {
  .p-md-2 {
    padding: 0.5rem !important;
  }
}
.p-3 {
  padding: 1rem !important;
}

@media (min-width: 576px) {
  .p-sm-3 {
    padding: 1rem !important;
  }
}
@media (min-width: 768px) {
  .p-md-3 {
    padding: 1rem !important;
  }
}
.p-4 {
  padding: 1.5rem !important;
}

@media (min-width: 576px) {
  .p-sm-4 {
    padding: 1.5rem !important;
  }
}
@media (min-width: 768px) {
  .p-md-4 {
    padding: 1.5rem !important;
  }
}
.p-5 {
  padding: 2rem !important;
}

@media (min-width: 576px) {
  .p-sm-5 {
    padding: 2rem !important;
  }
}
@media (min-width: 768px) {
  .p-md-5 {
    padding: 2rem !important;
  }
}
.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

@media (min-width: 576px) {
  .px-sm-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
@media (min-width: 768px) {
  .px-md-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
.px-1 {
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
}

@media (min-width: 576px) {
  .px-sm-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
}
@media (min-width: 768px) {
  .px-md-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
}
.px-2 {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

@media (min-width: 576px) {
  .px-sm-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
}
@media (min-width: 768px) {
  .px-md-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
}
.px-3 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

@media (min-width: 576px) {
  .px-sm-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}
@media (min-width: 768px) {
  .px-md-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}
.px-4 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

@media (min-width: 576px) {
  .px-sm-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
}
@media (min-width: 768px) {
  .px-md-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
}
.px-5 {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

@media (min-width: 576px) {
  .px-sm-5 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
}
@media (min-width: 768px) {
  .px-md-5 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
}
.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

@media (min-width: 576px) {
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}
@media (min-width: 768px) {
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}
.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

@media (min-width: 576px) {
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
}
@media (min-width: 768px) {
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
}
.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

@media (min-width: 576px) {
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
}
@media (min-width: 768px) {
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
}
.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

@media (min-width: 576px) {
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
}
@media (min-width: 768px) {
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
}
.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

@media (min-width: 576px) {
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
}
@media (min-width: 768px) {
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
}
.py-5 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

@media (min-width: 576px) {
  .py-sm-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
}
@media (min-width: 768px) {
  .py-md-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
}
.pt-0 {
  padding-top: 0 !important;
}

@media (min-width: 576px) {
  .pt-sm-0 {
    padding-top: 0 !important;
  }
}
@media (min-width: 768px) {
  .pt-md-0 {
    padding-top: 0 !important;
  }
}
.pt-1 {
  padding-top: 0.25rem !important;
}

@media (min-width: 576px) {
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
}
@media (min-width: 768px) {
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
}
.pt-2 {
  padding-top: 0.5rem !important;
}

@media (min-width: 576px) {
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
}
@media (min-width: 768px) {
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
}
.pt-3 {
  padding-top: 1rem !important;
}

@media (min-width: 576px) {
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
}
@media (min-width: 768px) {
  .pt-md-3 {
    padding-top: 1rem !important;
  }
}
.pt-4 {
  padding-top: 1.5rem !important;
}

@media (min-width: 576px) {
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
}
@media (min-width: 768px) {
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
}
.pt-5 {
  padding-top: 2rem !important;
}

@media (min-width: 576px) {
  .pt-sm-5 {
    padding-top: 2rem !important;
  }
}
@media (min-width: 768px) {
  .pt-md-5 {
    padding-top: 2rem !important;
  }
}
.pr-0 {
  padding-right: 0 !important;
}

@media (min-width: 576px) {
  .pr-sm-0 {
    padding-right: 0 !important;
  }
}
@media (min-width: 768px) {
  .pr-md-0 {
    padding-right: 0 !important;
  }
}
.pr-1 {
  padding-right: 0.25rem !important;
}

@media (min-width: 576px) {
  .pr-sm-1 {
    padding-right: 0.25rem !important;
  }
}
@media (min-width: 768px) {
  .pr-md-1 {
    padding-right: 0.25rem !important;
  }
}
.pr-2 {
  padding-right: 0.5rem !important;
}

@media (min-width: 576px) {
  .pr-sm-2 {
    padding-right: 0.5rem !important;
  }
}
@media (min-width: 768px) {
  .pr-md-2 {
    padding-right: 0.5rem !important;
  }
}
.pr-3 {
  padding-right: 1rem !important;
}

@media (min-width: 576px) {
  .pr-sm-3 {
    padding-right: 1rem !important;
  }
}
@media (min-width: 768px) {
  .pr-md-3 {
    padding-right: 1rem !important;
  }
}
.pr-4 {
  padding-right: 1.5rem !important;
}

@media (min-width: 576px) {
  .pr-sm-4 {
    padding-right: 1.5rem !important;
  }
}
@media (min-width: 768px) {
  .pr-md-4 {
    padding-right: 1.5rem !important;
  }
}
.pr-5 {
  padding-right: 2rem !important;
}

@media (min-width: 576px) {
  .pr-sm-5 {
    padding-right: 2rem !important;
  }
}
@media (min-width: 768px) {
  .pr-md-5 {
    padding-right: 2rem !important;
  }
}
.pb-0 {
  padding-bottom: 0 !important;
}

@media (min-width: 576px) {
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
}
@media (min-width: 768px) {
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
}
.pb-1 {
  padding-bottom: 0.25rem !important;
}

@media (min-width: 576px) {
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
}
@media (min-width: 768px) {
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
}
.pb-2 {
  padding-bottom: 0.5rem !important;
}

@media (min-width: 576px) {
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
}
@media (min-width: 768px) {
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
}
.pb-3 {
  padding-bottom: 1rem !important;
}

@media (min-width: 576px) {
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
}
@media (min-width: 768px) {
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
}
.pb-4 {
  padding-bottom: 1.5rem !important;
}

@media (min-width: 576px) {
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
}
@media (min-width: 768px) {
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
}
.pb-5 {
  padding-bottom: 2rem !important;
}

@media (min-width: 576px) {
  .pb-sm-5 {
    padding-bottom: 2rem !important;
  }
}
@media (min-width: 768px) {
  .pb-md-5 {
    padding-bottom: 2rem !important;
  }
}
.pl-0 {
  padding-left: 0 !important;
}

@media (min-width: 576px) {
  .pl-sm-0 {
    padding-left: 0 !important;
  }
}
@media (min-width: 768px) {
  .pl-md-0 {
    padding-left: 0 !important;
  }
}
.pl-1 {
  padding-left: 0.25rem !important;
}

@media (min-width: 576px) {
  .pl-sm-1 {
    padding-left: 0.25rem !important;
  }
}
@media (min-width: 768px) {
  .pl-md-1 {
    padding-left: 0.25rem !important;
  }
}
.pl-2 {
  padding-left: 0.5rem !important;
}

@media (min-width: 576px) {
  .pl-sm-2 {
    padding-left: 0.5rem !important;
  }
}
@media (min-width: 768px) {
  .pl-md-2 {
    padding-left: 0.5rem !important;
  }
}
.pl-3 {
  padding-left: 1rem !important;
}

@media (min-width: 576px) {
  .pl-sm-3 {
    padding-left: 1rem !important;
  }
}
@media (min-width: 768px) {
  .pl-md-3 {
    padding-left: 1rem !important;
  }
}
.pl-4 {
  padding-left: 1.5rem !important;
}

@media (min-width: 576px) {
  .pl-sm-4 {
    padding-left: 1.5rem !important;
  }
}
@media (min-width: 768px) {
  .pl-md-4 {
    padding-left: 1.5rem !important;
  }
}
.pl-5 {
  padding-left: 2rem !important;
}

@media (min-width: 576px) {
  .pl-sm-5 {
    padding-left: 2rem !important;
  }
}
@media (min-width: 768px) {
  .pl-md-5 {
    padding-left: 2rem !important;
  }
}
.m-0 {
  margin: 0 !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
}
.m-1 {
  margin: 0.25rem !important;
}

@media (min-width: 576px) {
  .m-sm-1 {
    margin: 0.25rem !important;
  }
}
@media (min-width: 768px) {
  .m-md-1 {
    margin: 0.25rem !important;
  }
}
.m-2 {
  margin: 0.5rem !important;
}

@media (min-width: 576px) {
  .m-sm-2 {
    margin: 0.5rem !important;
  }
}
@media (min-width: 768px) {
  .m-md-2 {
    margin: 0.5rem !important;
  }
}
.m-3 {
  margin: 1rem !important;
}

@media (min-width: 576px) {
  .m-sm-3 {
    margin: 1rem !important;
  }
}
@media (min-width: 768px) {
  .m-md-3 {
    margin: 1rem !important;
  }
}
.m-4 {
  margin: 1.5rem !important;
}

@media (min-width: 576px) {
  .m-sm-4 {
    margin: 1.5rem !important;
  }
}
@media (min-width: 768px) {
  .m-md-4 {
    margin: 1.5rem !important;
  }
}
.m-5 {
  margin: 2rem !important;
}

@media (min-width: 576px) {
  .m-sm-5 {
    margin: 2rem !important;
  }
}
@media (min-width: 768px) {
  .m-md-5 {
    margin: 2rem !important;
  }
}
.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

@media (min-width: 576px) {
  .mx-sm-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
@media (min-width: 768px) {
  .mx-md-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
.mx-1 {
  margin-left: 0.25rem !important;
  margin-right: 0.25rem !important;
}

@media (min-width: 576px) {
  .mx-sm-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }
}
@media (min-width: 768px) {
  .mx-md-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }
}
.mx-2 {
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
}

@media (min-width: 576px) {
  .mx-sm-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
}
@media (min-width: 768px) {
  .mx-md-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
}
.mx-3 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

@media (min-width: 576px) {
  .mx-sm-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
}
@media (min-width: 768px) {
  .mx-md-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
}
.mx-4 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}

@media (min-width: 576px) {
  .mx-sm-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
}
@media (min-width: 768px) {
  .mx-md-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
}
.mx-5 {
  margin-left: 2rem !important;
  margin-right: 2rem !important;
}

@media (min-width: 576px) {
  .mx-sm-5 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }
}
@media (min-width: 768px) {
  .mx-md-5 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }
}
.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

@media (min-width: 576px) {
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}
@media (min-width: 768px) {
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}
.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

@media (min-width: 576px) {
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
}
@media (min-width: 768px) {
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
}
.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

@media (min-width: 576px) {
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
}
@media (min-width: 768px) {
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
}
.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

@media (min-width: 576px) {
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
}
@media (min-width: 768px) {
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
}
.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

@media (min-width: 576px) {
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
}
@media (min-width: 768px) {
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
}
.my-5 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

@media (min-width: 576px) {
  .my-sm-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
}
@media (min-width: 768px) {
  .my-md-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
}
.mt-0 {
  margin-top: 0 !important;
}

@media (min-width: 576px) {
  .mt-sm-0 {
    margin-top: 0 !important;
  }
}
@media (min-width: 768px) {
  .mt-md-0 {
    margin-top: 0 !important;
  }
}
.mt-1 {
  margin-top: 0.25rem !important;
}

@media (min-width: 576px) {
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
}
@media (min-width: 768px) {
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
}
.mt-2 {
  margin-top: 0.5rem !important;
}

@media (min-width: 576px) {
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
}
@media (min-width: 768px) {
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
}
.mt-3 {
  margin-top: 1rem !important;
}

@media (min-width: 576px) {
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
}
@media (min-width: 768px) {
  .mt-md-3 {
    margin-top: 1rem !important;
  }
}
.mt-4 {
  margin-top: 1.5rem !important;
}

@media (min-width: 576px) {
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
}
@media (min-width: 768px) {
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
}
.mt-5 {
  margin-top: 2rem !important;
}

@media (min-width: 576px) {
  .mt-sm-5 {
    margin-top: 2rem !important;
  }
}
@media (min-width: 768px) {
  .mt-md-5 {
    margin-top: 2rem !important;
  }
}
.mr-0 {
  margin-right: 0 !important;
}

@media (min-width: 576px) {
  .mr-sm-0 {
    margin-right: 0 !important;
  }
}
@media (min-width: 768px) {
  .mr-md-0 {
    margin-right: 0 !important;
  }
}
.mr-1 {
  margin-right: 0.25rem !important;
}

@media (min-width: 576px) {
  .mr-sm-1 {
    margin-right: 0.25rem !important;
  }
}
@media (min-width: 768px) {
  .mr-md-1 {
    margin-right: 0.25rem !important;
  }
}
.mr-2 {
  margin-right: 0.5rem !important;
}

@media (min-width: 576px) {
  .mr-sm-2 {
    margin-right: 0.5rem !important;
  }
}
@media (min-width: 768px) {
  .mr-md-2 {
    margin-right: 0.5rem !important;
  }
}
.mr-3 {
  margin-right: 1rem !important;
}

@media (min-width: 576px) {
  .mr-sm-3 {
    margin-right: 1rem !important;
  }
}
@media (min-width: 768px) {
  .mr-md-3 {
    margin-right: 1rem !important;
  }
}
.mr-4 {
  margin-right: 1.5rem !important;
}

@media (min-width: 576px) {
  .mr-sm-4 {
    margin-right: 1.5rem !important;
  }
}
@media (min-width: 768px) {
  .mr-md-4 {
    margin-right: 1.5rem !important;
  }
}
.mr-5 {
  margin-right: 2rem !important;
}

@media (min-width: 576px) {
  .mr-sm-5 {
    margin-right: 2rem !important;
  }
}
@media (min-width: 768px) {
  .mr-md-5 {
    margin-right: 2rem !important;
  }
}
.mb-0 {
  margin-bottom: 0 !important;
}

@media (min-width: 576px) {
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
}
@media (min-width: 768px) {
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
}
.mb-1 {
  margin-bottom: 0.25rem !important;
}

@media (min-width: 576px) {
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
}
@media (min-width: 768px) {
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
}
.mb-2 {
  margin-bottom: 0.5rem !important;
}

@media (min-width: 576px) {
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
}
@media (min-width: 768px) {
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
}
.mb-3 {
  margin-bottom: 1rem !important;
}

@media (min-width: 576px) {
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
}
@media (min-width: 768px) {
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
}
.mb-4 {
  margin-bottom: 1.5rem !important;
}

@media (min-width: 576px) {
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
}
@media (min-width: 768px) {
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
}
.mb-5 {
  margin-bottom: 2rem !important;
}

@media (min-width: 576px) {
  .mb-sm-5 {
    margin-bottom: 2rem !important;
  }
}
@media (min-width: 768px) {
  .mb-md-5 {
    margin-bottom: 2rem !important;
  }
}
.ml-0 {
  margin-left: 0 !important;
}

@media (min-width: 576px) {
  .ml-sm-0 {
    margin-left: 0 !important;
  }
}
@media (min-width: 768px) {
  .ml-md-0 {
    margin-left: 0 !important;
  }
}
.ml-1 {
  margin-left: 0.25rem !important;
}

@media (min-width: 576px) {
  .ml-sm-1 {
    margin-left: 0.25rem !important;
  }
}
@media (min-width: 768px) {
  .ml-md-1 {
    margin-left: 0.25rem !important;
  }
}
.ml-2 {
  margin-left: 0.5rem !important;
}

@media (min-width: 576px) {
  .ml-sm-2 {
    margin-left: 0.5rem !important;
  }
}
@media (min-width: 768px) {
  .ml-md-2 {
    margin-left: 0.5rem !important;
  }
}
.ml-3 {
  margin-left: 1rem !important;
}

@media (min-width: 576px) {
  .ml-sm-3 {
    margin-left: 1rem !important;
  }
}
@media (min-width: 768px) {
  .ml-md-3 {
    margin-left: 1rem !important;
  }
}
.ml-4 {
  margin-left: 1.5rem !important;
}

@media (min-width: 576px) {
  .ml-sm-4 {
    margin-left: 1.5rem !important;
  }
}
@media (min-width: 768px) {
  .ml-md-4 {
    margin-left: 1.5rem !important;
  }
}
.ml-5 {
  margin-left: 2rem !important;
}

@media (min-width: 576px) {
  .ml-sm-5 {
    margin-left: 2rem !important;
  }
}
@media (min-width: 768px) {
  .ml-md-5 {
    margin-left: 2rem !important;
  }
}
.p-0 {
  padding: 0 !important;
}

@media (min-width: 576px) {
  .p-sm-0 {
    padding: 0 !important;
  }
}
@media (min-width: 768px) {
  .p-md-0 {
    padding: 0 !important;
  }
}
.p-1 {
  padding: 0.25rem !important;
}

@media (min-width: 576px) {
  .p-sm-1 {
    padding: 0.25rem !important;
  }
}
@media (min-width: 768px) {
  .p-md-1 {
    padding: 0.25rem !important;
  }
}
.p-2 {
  padding: 0.5rem !important;
}

@media (min-width: 576px) {
  .p-sm-2 {
    padding: 0.5rem !important;
  }
}
@media (min-width: 768px) {
  .p-md-2 {
    padding: 0.5rem !important;
  }
}
.p-3 {
  padding: 1rem !important;
}

@media (min-width: 576px) {
  .p-sm-3 {
    padding: 1rem !important;
  }
}
@media (min-width: 768px) {
  .p-md-3 {
    padding: 1rem !important;
  }
}
.p-4 {
  padding: 1.5rem !important;
}

@media (min-width: 576px) {
  .p-sm-4 {
    padding: 1.5rem !important;
  }
}
@media (min-width: 768px) {
  .p-md-4 {
    padding: 1.5rem !important;
  }
}
.p-5 {
  padding: 2rem !important;
}

@media (min-width: 576px) {
  .p-sm-5 {
    padding: 2rem !important;
  }
}
@media (min-width: 768px) {
  .p-md-5 {
    padding: 2rem !important;
  }
}
.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

@media (min-width: 576px) {
  .px-sm-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
@media (min-width: 768px) {
  .px-md-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
.px-1 {
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
}

@media (min-width: 576px) {
  .px-sm-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
}
@media (min-width: 768px) {
  .px-md-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
}
.px-2 {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

@media (min-width: 576px) {
  .px-sm-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
}
@media (min-width: 768px) {
  .px-md-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
}
.px-3 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

@media (min-width: 576px) {
  .px-sm-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}
@media (min-width: 768px) {
  .px-md-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}
.px-4 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

@media (min-width: 576px) {
  .px-sm-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
}
@media (min-width: 768px) {
  .px-md-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
}
.px-5 {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

@media (min-width: 576px) {
  .px-sm-5 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
}
@media (min-width: 768px) {
  .px-md-5 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
}
.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

@media (min-width: 576px) {
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}
@media (min-width: 768px) {
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}
.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

@media (min-width: 576px) {
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
}
@media (min-width: 768px) {
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
}
.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

@media (min-width: 576px) {
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
}
@media (min-width: 768px) {
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
}
.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

@media (min-width: 576px) {
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
}
@media (min-width: 768px) {
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
}
.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

@media (min-width: 576px) {
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
}
@media (min-width: 768px) {
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
}
.py-5 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

@media (min-width: 576px) {
  .py-sm-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
}
@media (min-width: 768px) {
  .py-md-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
}
.pt-0 {
  padding-top: 0 !important;
}

@media (min-width: 576px) {
  .pt-sm-0 {
    padding-top: 0 !important;
  }
}
@media (min-width: 768px) {
  .pt-md-0 {
    padding-top: 0 !important;
  }
}
.pt-1 {
  padding-top: 0.25rem !important;
}

@media (min-width: 576px) {
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
}
@media (min-width: 768px) {
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
}
.pt-2 {
  padding-top: 0.5rem !important;
}

@media (min-width: 576px) {
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
}
@media (min-width: 768px) {
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
}
.pt-3 {
  padding-top: 1rem !important;
}

@media (min-width: 576px) {
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
}
@media (min-width: 768px) {
  .pt-md-3 {
    padding-top: 1rem !important;
  }
}
.pt-4 {
  padding-top: 1.5rem !important;
}

@media (min-width: 576px) {
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
}
@media (min-width: 768px) {
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
}
.pt-5 {
  padding-top: 2rem !important;
}

@media (min-width: 576px) {
  .pt-sm-5 {
    padding-top: 2rem !important;
  }
}
@media (min-width: 768px) {
  .pt-md-5 {
    padding-top: 2rem !important;
  }
}
.pr-0 {
  padding-right: 0 !important;
}

@media (min-width: 576px) {
  .pr-sm-0 {
    padding-right: 0 !important;
  }
}
@media (min-width: 768px) {
  .pr-md-0 {
    padding-right: 0 !important;
  }
}
.pr-1 {
  padding-right: 0.25rem !important;
}

@media (min-width: 576px) {
  .pr-sm-1 {
    padding-right: 0.25rem !important;
  }
}
@media (min-width: 768px) {
  .pr-md-1 {
    padding-right: 0.25rem !important;
  }
}
.pr-2 {
  padding-right: 0.5rem !important;
}

@media (min-width: 576px) {
  .pr-sm-2 {
    padding-right: 0.5rem !important;
  }
}
@media (min-width: 768px) {
  .pr-md-2 {
    padding-right: 0.5rem !important;
  }
}
.pr-3 {
  padding-right: 1rem !important;
}

@media (min-width: 576px) {
  .pr-sm-3 {
    padding-right: 1rem !important;
  }
}
@media (min-width: 768px) {
  .pr-md-3 {
    padding-right: 1rem !important;
  }
}
.pr-4 {
  padding-right: 1.5rem !important;
}

@media (min-width: 576px) {
  .pr-sm-4 {
    padding-right: 1.5rem !important;
  }
}
@media (min-width: 768px) {
  .pr-md-4 {
    padding-right: 1.5rem !important;
  }
}
.pr-5 {
  padding-right: 2rem !important;
}

@media (min-width: 576px) {
  .pr-sm-5 {
    padding-right: 2rem !important;
  }
}
@media (min-width: 768px) {
  .pr-md-5 {
    padding-right: 2rem !important;
  }
}
.pb-0 {
  padding-bottom: 0 !important;
}

@media (min-width: 576px) {
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
}
@media (min-width: 768px) {
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
}
.pb-1 {
  padding-bottom: 0.25rem !important;
}

@media (min-width: 576px) {
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
}
@media (min-width: 768px) {
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
}
.pb-2 {
  padding-bottom: 0.5rem !important;
}

@media (min-width: 576px) {
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
}
@media (min-width: 768px) {
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
}
.pb-3 {
  padding-bottom: 1rem !important;
}

@media (min-width: 576px) {
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
}
@media (min-width: 768px) {
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
}
.pb-4 {
  padding-bottom: 1.5rem !important;
}

@media (min-width: 576px) {
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
}
@media (min-width: 768px) {
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
}
.pb-5 {
  padding-bottom: 2rem !important;
}

@media (min-width: 576px) {
  .pb-sm-5 {
    padding-bottom: 2rem !important;
  }
}
@media (min-width: 768px) {
  .pb-md-5 {
    padding-bottom: 2rem !important;
  }
}
.pl-0 {
  padding-left: 0 !important;
}

@media (min-width: 576px) {
  .pl-sm-0 {
    padding-left: 0 !important;
  }
}
@media (min-width: 768px) {
  .pl-md-0 {
    padding-left: 0 !important;
  }
}
.pl-1 {
  padding-left: 0.25rem !important;
}

@media (min-width: 576px) {
  .pl-sm-1 {
    padding-left: 0.25rem !important;
  }
}
@media (min-width: 768px) {
  .pl-md-1 {
    padding-left: 0.25rem !important;
  }
}
.pl-2 {
  padding-left: 0.5rem !important;
}

@media (min-width: 576px) {
  .pl-sm-2 {
    padding-left: 0.5rem !important;
  }
}
@media (min-width: 768px) {
  .pl-md-2 {
    padding-left: 0.5rem !important;
  }
}
.pl-3 {
  padding-left: 1rem !important;
}

@media (min-width: 576px) {
  .pl-sm-3 {
    padding-left: 1rem !important;
  }
}
@media (min-width: 768px) {
  .pl-md-3 {
    padding-left: 1rem !important;
  }
}
.pl-4 {
  padding-left: 1.5rem !important;
}

@media (min-width: 576px) {
  .pl-sm-4 {
    padding-left: 1.5rem !important;
  }
}
@media (min-width: 768px) {
  .pl-md-4 {
    padding-left: 1.5rem !important;
  }
}
.pl-5 {
  padding-left: 2rem !important;
}

@media (min-width: 576px) {
  .pl-sm-5 {
    padding-left: 2rem !important;
  }
}
@media (min-width: 768px) {
  .pl-md-5 {
    padding-left: 2rem !important;
  }
}
.m-0 {
  margin: 0 !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
}
.m-1 {
  margin: 0.25rem !important;
}

@media (min-width: 576px) {
  .m-sm-1 {
    margin: 0.25rem !important;
  }
}
@media (min-width: 768px) {
  .m-md-1 {
    margin: 0.25rem !important;
  }
}
.m-2 {
  margin: 0.5rem !important;
}

@media (min-width: 576px) {
  .m-sm-2 {
    margin: 0.5rem !important;
  }
}
@media (min-width: 768px) {
  .m-md-2 {
    margin: 0.5rem !important;
  }
}
.m-3 {
  margin: 1rem !important;
}

@media (min-width: 576px) {
  .m-sm-3 {
    margin: 1rem !important;
  }
}
@media (min-width: 768px) {
  .m-md-3 {
    margin: 1rem !important;
  }
}
.m-4 {
  margin: 1.5rem !important;
}

@media (min-width: 576px) {
  .m-sm-4 {
    margin: 1.5rem !important;
  }
}
@media (min-width: 768px) {
  .m-md-4 {
    margin: 1.5rem !important;
  }
}
.m-5 {
  margin: 2rem !important;
}

@media (min-width: 576px) {
  .m-sm-5 {
    margin: 2rem !important;
  }
}
@media (min-width: 768px) {
  .m-md-5 {
    margin: 2rem !important;
  }
}
.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

@media (min-width: 576px) {
  .mx-sm-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
@media (min-width: 768px) {
  .mx-md-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
.mx-1 {
  margin-left: 0.25rem !important;
  margin-right: 0.25rem !important;
}

@media (min-width: 576px) {
  .mx-sm-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }
}
@media (min-width: 768px) {
  .mx-md-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }
}
.mx-2 {
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
}

@media (min-width: 576px) {
  .mx-sm-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
}
@media (min-width: 768px) {
  .mx-md-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
}
.mx-3 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

@media (min-width: 576px) {
  .mx-sm-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
}
@media (min-width: 768px) {
  .mx-md-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
}
.mx-4 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}

@media (min-width: 576px) {
  .mx-sm-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
}
@media (min-width: 768px) {
  .mx-md-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
}
.mx-5 {
  margin-left: 2rem !important;
  margin-right: 2rem !important;
}

@media (min-width: 576px) {
  .mx-sm-5 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }
}
@media (min-width: 768px) {
  .mx-md-5 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }
}
.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

@media (min-width: 576px) {
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}
@media (min-width: 768px) {
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}
.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

@media (min-width: 576px) {
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
}
@media (min-width: 768px) {
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
}
.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

@media (min-width: 576px) {
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
}
@media (min-width: 768px) {
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
}
.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

@media (min-width: 576px) {
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
}
@media (min-width: 768px) {
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
}
.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

@media (min-width: 576px) {
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
}
@media (min-width: 768px) {
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
}
.my-5 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

@media (min-width: 576px) {
  .my-sm-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
}
@media (min-width: 768px) {
  .my-md-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
}
.mt-0 {
  margin-top: 0 !important;
}

@media (min-width: 576px) {
  .mt-sm-0 {
    margin-top: 0 !important;
  }
}
@media (min-width: 768px) {
  .mt-md-0 {
    margin-top: 0 !important;
  }
}
.mt-1 {
  margin-top: 0.25rem !important;
}

@media (min-width: 576px) {
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
}
@media (min-width: 768px) {
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
}
.mt-2 {
  margin-top: 0.5rem !important;
}

@media (min-width: 576px) {
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
}
@media (min-width: 768px) {
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
}
.mt-3 {
  margin-top: 1rem !important;
}

@media (min-width: 576px) {
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
}
@media (min-width: 768px) {
  .mt-md-3 {
    margin-top: 1rem !important;
  }
}
.mt-4 {
  margin-top: 1.5rem !important;
}

@media (min-width: 576px) {
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
}
@media (min-width: 768px) {
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
}
.mt-5 {
  margin-top: 2rem !important;
}

@media (min-width: 576px) {
  .mt-sm-5 {
    margin-top: 2rem !important;
  }
}
@media (min-width: 768px) {
  .mt-md-5 {
    margin-top: 2rem !important;
  }
}
.mr-0 {
  margin-right: 0 !important;
}

@media (min-width: 576px) {
  .mr-sm-0 {
    margin-right: 0 !important;
  }
}
@media (min-width: 768px) {
  .mr-md-0 {
    margin-right: 0 !important;
  }
}
.mr-1 {
  margin-right: 0.25rem !important;
}

@media (min-width: 576px) {
  .mr-sm-1 {
    margin-right: 0.25rem !important;
  }
}
@media (min-width: 768px) {
  .mr-md-1 {
    margin-right: 0.25rem !important;
  }
}
.mr-2 {
  margin-right: 0.5rem !important;
}

@media (min-width: 576px) {
  .mr-sm-2 {
    margin-right: 0.5rem !important;
  }
}
@media (min-width: 768px) {
  .mr-md-2 {
    margin-right: 0.5rem !important;
  }
}
.mr-3 {
  margin-right: 1rem !important;
}

@media (min-width: 576px) {
  .mr-sm-3 {
    margin-right: 1rem !important;
  }
}
@media (min-width: 768px) {
  .mr-md-3 {
    margin-right: 1rem !important;
  }
}
.mr-4 {
  margin-right: 1.5rem !important;
}

@media (min-width: 576px) {
  .mr-sm-4 {
    margin-right: 1.5rem !important;
  }
}
@media (min-width: 768px) {
  .mr-md-4 {
    margin-right: 1.5rem !important;
  }
}
.mr-5 {
  margin-right: 2rem !important;
}

@media (min-width: 576px) {
  .mr-sm-5 {
    margin-right: 2rem !important;
  }
}
@media (min-width: 768px) {
  .mr-md-5 {
    margin-right: 2rem !important;
  }
}
.mb-0 {
  margin-bottom: 0 !important;
}

@media (min-width: 576px) {
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
}
@media (min-width: 768px) {
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
}
.mb-1 {
  margin-bottom: 0.25rem !important;
}

@media (min-width: 576px) {
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
}
@media (min-width: 768px) {
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
}
.mb-2 {
  margin-bottom: 0.5rem !important;
}

@media (min-width: 576px) {
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
}
@media (min-width: 768px) {
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
}
.mb-3 {
  margin-bottom: 1rem !important;
}

@media (min-width: 576px) {
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
}
@media (min-width: 768px) {
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
}
.mb-4 {
  margin-bottom: 1.5rem !important;
}

@media (min-width: 576px) {
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
}
@media (min-width: 768px) {
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
}
.mb-5 {
  margin-bottom: 2rem !important;
}

@media (min-width: 576px) {
  .mb-sm-5 {
    margin-bottom: 2rem !important;
  }
}
@media (min-width: 768px) {
  .mb-md-5 {
    margin-bottom: 2rem !important;
  }
}
.ml-0 {
  margin-left: 0 !important;
}

@media (min-width: 576px) {
  .ml-sm-0 {
    margin-left: 0 !important;
  }
}
@media (min-width: 768px) {
  .ml-md-0 {
    margin-left: 0 !important;
  }
}
.ml-1 {
  margin-left: 0.25rem !important;
}

@media (min-width: 576px) {
  .ml-sm-1 {
    margin-left: 0.25rem !important;
  }
}
@media (min-width: 768px) {
  .ml-md-1 {
    margin-left: 0.25rem !important;
  }
}
.ml-2 {
  margin-left: 0.5rem !important;
}

@media (min-width: 576px) {
  .ml-sm-2 {
    margin-left: 0.5rem !important;
  }
}
@media (min-width: 768px) {
  .ml-md-2 {
    margin-left: 0.5rem !important;
  }
}
.ml-3 {
  margin-left: 1rem !important;
}

@media (min-width: 576px) {
  .ml-sm-3 {
    margin-left: 1rem !important;
  }
}
@media (min-width: 768px) {
  .ml-md-3 {
    margin-left: 1rem !important;
  }
}
.ml-4 {
  margin-left: 1.5rem !important;
}

@media (min-width: 576px) {
  .ml-sm-4 {
    margin-left: 1.5rem !important;
  }
}
@media (min-width: 768px) {
  .ml-md-4 {
    margin-left: 1.5rem !important;
  }
}
.ml-5 {
  margin-left: 2rem !important;
}

@media (min-width: 576px) {
  .ml-sm-5 {
    margin-left: 2rem !important;
  }
}
@media (min-width: 768px) {
  .ml-md-5 {
    margin-left: 2rem !important;
  }
}
@media (max-width: 575.98px) {
  .p-0 {
    padding: 0 !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .p-sm-0 {
    padding: 0 !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .p-md-0 {
    padding: 0 !important;
  }
}
@media (max-width: 575.98px) {
  .p-1 {
    padding: 0.25rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .p-sm-1 {
    padding: 0.25rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .p-md-1 {
    padding: 0.25rem !important;
  }
}
@media (max-width: 575.98px) {
  .p-2 {
    padding: 0.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .p-sm-2 {
    padding: 0.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .p-md-2 {
    padding: 0.5rem !important;
  }
}
@media (max-width: 575.98px) {
  .p-3 {
    padding: 1rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .p-sm-3 {
    padding: 1rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .p-md-3 {
    padding: 1rem !important;
  }
}
@media (max-width: 575.98px) {
  .p-4 {
    padding: 1.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .p-sm-4 {
    padding: 1.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .p-md-4 {
    padding: 1.5rem !important;
  }
}
@media (max-width: 575.98px) {
  .p-5 {
    padding: 2rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .p-sm-5 {
    padding: 2rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .p-md-5 {
    padding: 2rem !important;
  }
}
@media (max-width: 575.98px) {
  .px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .px-sm-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .px-md-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
@media (max-width: 575.98px) {
  .px-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .px-sm-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .px-md-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
}
@media (max-width: 575.98px) {
  .px-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .px-sm-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .px-md-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
}
@media (max-width: 575.98px) {
  .px-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .px-sm-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .px-md-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}
@media (max-width: 575.98px) {
  .px-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .px-sm-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .px-md-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
}
@media (max-width: 575.98px) {
  .px-5 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .px-sm-5 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .px-md-5 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
}
@media (max-width: 575.98px) {
  .py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}
@media (max-width: 575.98px) {
  .py-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
}
@media (max-width: 575.98px) {
  .py-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
}
@media (max-width: 575.98px) {
  .py-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
}
@media (max-width: 575.98px) {
  .py-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
}
@media (max-width: 575.98px) {
  .py-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .py-sm-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .py-md-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
}
@media (max-width: 575.98px) {
  .pt-0 {
    padding-top: 0 !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .pt-sm-0 {
    padding-top: 0 !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .pt-md-0 {
    padding-top: 0 !important;
  }
}
@media (max-width: 575.98px) {
  .pt-1 {
    padding-top: 0.25rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
}
@media (max-width: 575.98px) {
  .pt-2 {
    padding-top: 0.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
}
@media (max-width: 575.98px) {
  .pt-3 {
    padding-top: 1rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .pt-md-3 {
    padding-top: 1rem !important;
  }
}
@media (max-width: 575.98px) {
  .pt-4 {
    padding-top: 1.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
}
@media (max-width: 575.98px) {
  .pt-5 {
    padding-top: 2rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .pt-sm-5 {
    padding-top: 2rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .pt-md-5 {
    padding-top: 2rem !important;
  }
}
@media (max-width: 575.98px) {
  .pr-0 {
    padding-right: 0 !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .pr-sm-0 {
    padding-right: 0 !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .pr-md-0 {
    padding-right: 0 !important;
  }
}
@media (max-width: 575.98px) {
  .pr-1 {
    padding-right: 0.25rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .pr-sm-1 {
    padding-right: 0.25rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .pr-md-1 {
    padding-right: 0.25rem !important;
  }
}
@media (max-width: 575.98px) {
  .pr-2 {
    padding-right: 0.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .pr-sm-2 {
    padding-right: 0.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .pr-md-2 {
    padding-right: 0.5rem !important;
  }
}
@media (max-width: 575.98px) {
  .pr-3 {
    padding-right: 1rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .pr-sm-3 {
    padding-right: 1rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .pr-md-3 {
    padding-right: 1rem !important;
  }
}
@media (max-width: 575.98px) {
  .pr-4 {
    padding-right: 1.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .pr-sm-4 {
    padding-right: 1.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .pr-md-4 {
    padding-right: 1.5rem !important;
  }
}
@media (max-width: 575.98px) {
  .pr-5 {
    padding-right: 2rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .pr-sm-5 {
    padding-right: 2rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .pr-md-5 {
    padding-right: 2rem !important;
  }
}
@media (max-width: 575.98px) {
  .pb-0 {
    padding-bottom: 0 !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
}
@media (max-width: 575.98px) {
  .pb-1 {
    padding-bottom: 0.25rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
}
@media (max-width: 575.98px) {
  .pb-2 {
    padding-bottom: 0.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
}
@media (max-width: 575.98px) {
  .pb-3 {
    padding-bottom: 1rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
}
@media (max-width: 575.98px) {
  .pb-4 {
    padding-bottom: 1.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
}
@media (max-width: 575.98px) {
  .pb-5 {
    padding-bottom: 2rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .pb-sm-5 {
    padding-bottom: 2rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .pb-md-5 {
    padding-bottom: 2rem !important;
  }
}
@media (max-width: 575.98px) {
  .pl-0 {
    padding-left: 0 !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .pl-sm-0 {
    padding-left: 0 !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .pl-md-0 {
    padding-left: 0 !important;
  }
}
@media (max-width: 575.98px) {
  .pl-1 {
    padding-left: 0.25rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .pl-sm-1 {
    padding-left: 0.25rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .pl-md-1 {
    padding-left: 0.25rem !important;
  }
}
@media (max-width: 575.98px) {
  .pl-2 {
    padding-left: 0.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .pl-sm-2 {
    padding-left: 0.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .pl-md-2 {
    padding-left: 0.5rem !important;
  }
}
@media (max-width: 575.98px) {
  .pl-3 {
    padding-left: 1rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .pl-sm-3 {
    padding-left: 1rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .pl-md-3 {
    padding-left: 1rem !important;
  }
}
@media (max-width: 575.98px) {
  .pl-4 {
    padding-left: 1.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .pl-sm-4 {
    padding-left: 1.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .pl-md-4 {
    padding-left: 1.5rem !important;
  }
}
@media (max-width: 575.98px) {
  .pl-5 {
    padding-left: 2rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .pl-sm-5 {
    padding-left: 2rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .pl-md-5 {
    padding-left: 2rem !important;
  }
}
@media (max-width: 575.98px) {
  .m-0 {
    margin: 0 !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
}
@media (max-width: 575.98px) {
  .m-1 {
    margin: 0.25rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .m-sm-1 {
    margin: 0.25rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .m-md-1 {
    margin: 0.25rem !important;
  }
}
@media (max-width: 575.98px) {
  .m-2 {
    margin: 0.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .m-sm-2 {
    margin: 0.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .m-md-2 {
    margin: 0.5rem !important;
  }
}
@media (max-width: 575.98px) {
  .m-3 {
    margin: 1rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .m-sm-3 {
    margin: 1rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .m-md-3 {
    margin: 1rem !important;
  }
}
@media (max-width: 575.98px) {
  .m-4 {
    margin: 1.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .m-sm-4 {
    margin: 1.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .m-md-4 {
    margin: 1.5rem !important;
  }
}
@media (max-width: 575.98px) {
  .m-5 {
    margin: 2rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .m-sm-5 {
    margin: 2rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .m-md-5 {
    margin: 2rem !important;
  }
}
@media (max-width: 575.98px) {
  .mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .mx-sm-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .mx-md-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
@media (max-width: 575.98px) {
  .mx-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .mx-sm-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .mx-md-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }
}
@media (max-width: 575.98px) {
  .mx-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .mx-sm-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .mx-md-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
}
@media (max-width: 575.98px) {
  .mx-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .mx-sm-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .mx-md-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
}
@media (max-width: 575.98px) {
  .mx-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .mx-sm-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .mx-md-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
}
@media (max-width: 575.98px) {
  .mx-5 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .mx-sm-5 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .mx-md-5 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }
}
@media (max-width: 575.98px) {
  .my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}
@media (max-width: 575.98px) {
  .my-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
}
@media (max-width: 575.98px) {
  .my-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
}
@media (max-width: 575.98px) {
  .my-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
}
@media (max-width: 575.98px) {
  .my-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
}
@media (max-width: 575.98px) {
  .my-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .my-sm-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .my-md-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
}
@media (max-width: 575.98px) {
  .mt-0 {
    margin-top: 0 !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .mt-sm-0 {
    margin-top: 0 !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .mt-md-0 {
    margin-top: 0 !important;
  }
}
@media (max-width: 575.98px) {
  .mt-1 {
    margin-top: 0.25rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
}
@media (max-width: 575.98px) {
  .mt-2 {
    margin-top: 0.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
}
@media (max-width: 575.98px) {
  .mt-3 {
    margin-top: 1rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .mt-md-3 {
    margin-top: 1rem !important;
  }
}
@media (max-width: 575.98px) {
  .mt-4 {
    margin-top: 1.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
}
@media (max-width: 575.98px) {
  .mt-5 {
    margin-top: 2rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .mt-sm-5 {
    margin-top: 2rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .mt-md-5 {
    margin-top: 2rem !important;
  }
}
@media (max-width: 575.98px) {
  .mr-0 {
    margin-right: 0 !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .mr-sm-0 {
    margin-right: 0 !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .mr-md-0 {
    margin-right: 0 !important;
  }
}
@media (max-width: 575.98px) {
  .mr-1 {
    margin-right: 0.25rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .mr-sm-1 {
    margin-right: 0.25rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .mr-md-1 {
    margin-right: 0.25rem !important;
  }
}
@media (max-width: 575.98px) {
  .mr-2 {
    margin-right: 0.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .mr-sm-2 {
    margin-right: 0.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .mr-md-2 {
    margin-right: 0.5rem !important;
  }
}
@media (max-width: 575.98px) {
  .mr-3 {
    margin-right: 1rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .mr-sm-3 {
    margin-right: 1rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .mr-md-3 {
    margin-right: 1rem !important;
  }
}
@media (max-width: 575.98px) {
  .mr-4 {
    margin-right: 1.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .mr-sm-4 {
    margin-right: 1.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .mr-md-4 {
    margin-right: 1.5rem !important;
  }
}
@media (max-width: 575.98px) {
  .mr-5 {
    margin-right: 2rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .mr-sm-5 {
    margin-right: 2rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .mr-md-5 {
    margin-right: 2rem !important;
  }
}
@media (max-width: 575.98px) {
  .mb-0 {
    margin-bottom: 0 !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
}
@media (max-width: 575.98px) {
  .mb-1 {
    margin-bottom: 0.25rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
}
@media (max-width: 575.98px) {
  .mb-2 {
    margin-bottom: 0.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
}
@media (max-width: 575.98px) {
  .mb-3 {
    margin-bottom: 1rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
}
@media (max-width: 575.98px) {
  .mb-4 {
    margin-bottom: 1.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
}
@media (max-width: 575.98px) {
  .mb-5 {
    margin-bottom: 2rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .mb-sm-5 {
    margin-bottom: 2rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .mb-md-5 {
    margin-bottom: 2rem !important;
  }
}
@media (max-width: 575.98px) {
  .ml-0 {
    margin-left: 0 !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .ml-sm-0 {
    margin-left: 0 !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .ml-md-0 {
    margin-left: 0 !important;
  }
}
@media (max-width: 575.98px) {
  .ml-1 {
    margin-left: 0.25rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .ml-sm-1 {
    margin-left: 0.25rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .ml-md-1 {
    margin-left: 0.25rem !important;
  }
}
@media (max-width: 575.98px) {
  .ml-2 {
    margin-left: 0.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .ml-sm-2 {
    margin-left: 0.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .ml-md-2 {
    margin-left: 0.5rem !important;
  }
}
@media (max-width: 575.98px) {
  .ml-3 {
    margin-left: 1rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .ml-sm-3 {
    margin-left: 1rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .ml-md-3 {
    margin-left: 1rem !important;
  }
}
@media (max-width: 575.98px) {
  .ml-4 {
    margin-left: 1.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .ml-sm-4 {
    margin-left: 1.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .ml-md-4 {
    margin-left: 1.5rem !important;
  }
}
@media (max-width: 575.98px) {
  .ml-5 {
    margin-left: 2rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .ml-sm-5 {
    margin-left: 2rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .ml-md-5 {
    margin-left: 2rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .p-0 {
    padding: 0 !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .p-sm-0 {
    padding: 0 !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .p-md-0 {
    padding: 0 !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .p-1 {
    padding: 0.25rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .p-sm-1 {
    padding: 0.25rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .p-md-1 {
    padding: 0.25rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .p-2 {
    padding: 0.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .p-sm-2 {
    padding: 0.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .p-md-2 {
    padding: 0.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .p-3 {
    padding: 1rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .p-sm-3 {
    padding: 1rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .p-md-3 {
    padding: 1rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .p-4 {
    padding: 1.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .p-sm-4 {
    padding: 1.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .p-md-4 {
    padding: 1.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .p-5 {
    padding: 2rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .p-sm-5 {
    padding: 2rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .p-md-5 {
    padding: 2rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .px-sm-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .px-md-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .px-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .px-sm-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .px-md-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .px-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .px-sm-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .px-md-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .px-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .px-sm-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .px-md-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .px-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .px-sm-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .px-md-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .px-5 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .px-sm-5 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .px-md-5 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .py-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .py-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .py-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .py-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .py-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .py-sm-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .py-md-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .pt-0 {
    padding-top: 0 !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .pt-sm-0 {
    padding-top: 0 !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .pt-md-0 {
    padding-top: 0 !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .pt-1 {
    padding-top: 0.25rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .pt-2 {
    padding-top: 0.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .pt-3 {
    padding-top: 1rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .pt-md-3 {
    padding-top: 1rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .pt-4 {
    padding-top: 1.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .pt-5 {
    padding-top: 2rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .pt-sm-5 {
    padding-top: 2rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .pt-md-5 {
    padding-top: 2rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .pr-0 {
    padding-right: 0 !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .pr-sm-0 {
    padding-right: 0 !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .pr-md-0 {
    padding-right: 0 !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .pr-1 {
    padding-right: 0.25rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .pr-sm-1 {
    padding-right: 0.25rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .pr-md-1 {
    padding-right: 0.25rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .pr-2 {
    padding-right: 0.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .pr-sm-2 {
    padding-right: 0.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .pr-md-2 {
    padding-right: 0.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .pr-3 {
    padding-right: 1rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .pr-sm-3 {
    padding-right: 1rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .pr-md-3 {
    padding-right: 1rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .pr-4 {
    padding-right: 1.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .pr-sm-4 {
    padding-right: 1.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .pr-md-4 {
    padding-right: 1.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .pr-5 {
    padding-right: 2rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .pr-sm-5 {
    padding-right: 2rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .pr-md-5 {
    padding-right: 2rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .pb-0 {
    padding-bottom: 0 !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .pb-1 {
    padding-bottom: 0.25rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .pb-2 {
    padding-bottom: 0.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .pb-3 {
    padding-bottom: 1rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .pb-4 {
    padding-bottom: 1.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .pb-5 {
    padding-bottom: 2rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .pb-sm-5 {
    padding-bottom: 2rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .pb-md-5 {
    padding-bottom: 2rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .pl-0 {
    padding-left: 0 !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .pl-sm-0 {
    padding-left: 0 !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .pl-md-0 {
    padding-left: 0 !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .pl-1 {
    padding-left: 0.25rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .pl-sm-1 {
    padding-left: 0.25rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .pl-md-1 {
    padding-left: 0.25rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .pl-2 {
    padding-left: 0.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .pl-sm-2 {
    padding-left: 0.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .pl-md-2 {
    padding-left: 0.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .pl-3 {
    padding-left: 1rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .pl-sm-3 {
    padding-left: 1rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .pl-md-3 {
    padding-left: 1rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .pl-4 {
    padding-left: 1.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .pl-sm-4 {
    padding-left: 1.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .pl-md-4 {
    padding-left: 1.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .pl-5 {
    padding-left: 2rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .pl-sm-5 {
    padding-left: 2rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .pl-md-5 {
    padding-left: 2rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .m-0 {
    margin: 0 !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .m-1 {
    margin: 0.25rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .m-sm-1 {
    margin: 0.25rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .m-md-1 {
    margin: 0.25rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .m-2 {
    margin: 0.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .m-sm-2 {
    margin: 0.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .m-md-2 {
    margin: 0.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .m-3 {
    margin: 1rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .m-sm-3 {
    margin: 1rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .m-md-3 {
    margin: 1rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .m-4 {
    margin: 1.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .m-sm-4 {
    margin: 1.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .m-md-4 {
    margin: 1.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .m-5 {
    margin: 2rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .m-sm-5 {
    margin: 2rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .m-md-5 {
    margin: 2rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .mx-sm-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .mx-md-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .mx-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .mx-sm-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .mx-md-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .mx-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .mx-sm-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .mx-md-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .mx-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .mx-sm-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .mx-md-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .mx-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .mx-sm-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .mx-md-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .mx-5 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .mx-sm-5 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .mx-md-5 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .my-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .my-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .my-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .my-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .my-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .my-sm-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .my-md-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .mt-0 {
    margin-top: 0 !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .mt-sm-0 {
    margin-top: 0 !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .mt-md-0 {
    margin-top: 0 !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .mt-1 {
    margin-top: 0.25rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .mt-2 {
    margin-top: 0.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .mt-3 {
    margin-top: 1rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .mt-md-3 {
    margin-top: 1rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .mt-4 {
    margin-top: 1.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .mt-5 {
    margin-top: 2rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .mt-sm-5 {
    margin-top: 2rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .mt-md-5 {
    margin-top: 2rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .mr-0 {
    margin-right: 0 !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .mr-sm-0 {
    margin-right: 0 !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .mr-md-0 {
    margin-right: 0 !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .mr-1 {
    margin-right: 0.25rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .mr-sm-1 {
    margin-right: 0.25rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .mr-md-1 {
    margin-right: 0.25rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .mr-2 {
    margin-right: 0.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .mr-sm-2 {
    margin-right: 0.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .mr-md-2 {
    margin-right: 0.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .mr-3 {
    margin-right: 1rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .mr-sm-3 {
    margin-right: 1rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .mr-md-3 {
    margin-right: 1rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .mr-4 {
    margin-right: 1.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .mr-sm-4 {
    margin-right: 1.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .mr-md-4 {
    margin-right: 1.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .mr-5 {
    margin-right: 2rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .mr-sm-5 {
    margin-right: 2rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .mr-md-5 {
    margin-right: 2rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .mb-0 {
    margin-bottom: 0 !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .mb-1 {
    margin-bottom: 0.25rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .mb-2 {
    margin-bottom: 0.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .mb-3 {
    margin-bottom: 1rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .mb-4 {
    margin-bottom: 1.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .mb-5 {
    margin-bottom: 2rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .mb-sm-5 {
    margin-bottom: 2rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .mb-md-5 {
    margin-bottom: 2rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .ml-0 {
    margin-left: 0 !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .ml-sm-0 {
    margin-left: 0 !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .ml-md-0 {
    margin-left: 0 !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .ml-1 {
    margin-left: 0.25rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .ml-sm-1 {
    margin-left: 0.25rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .ml-md-1 {
    margin-left: 0.25rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .ml-2 {
    margin-left: 0.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .ml-sm-2 {
    margin-left: 0.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .ml-md-2 {
    margin-left: 0.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .ml-3 {
    margin-left: 1rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .ml-sm-3 {
    margin-left: 1rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .ml-md-3 {
    margin-left: 1rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .ml-4 {
    margin-left: 1.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .ml-sm-4 {
    margin-left: 1.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .ml-md-4 {
    margin-left: 1.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .ml-5 {
    margin-left: 2rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .ml-sm-5 {
    margin-left: 2rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .ml-md-5 {
    margin-left: 2rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .p-0 {
    padding: 0 !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .p-sm-0 {
    padding: 0 !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .p-md-0 {
    padding: 0 !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .p-1 {
    padding: 0.25rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .p-sm-1 {
    padding: 0.25rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .p-md-1 {
    padding: 0.25rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .p-2 {
    padding: 0.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .p-sm-2 {
    padding: 0.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .p-md-2 {
    padding: 0.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .p-3 {
    padding: 1rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .p-sm-3 {
    padding: 1rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .p-md-3 {
    padding: 1rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .p-4 {
    padding: 1.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .p-sm-4 {
    padding: 1.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .p-md-4 {
    padding: 1.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .p-5 {
    padding: 2rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .p-sm-5 {
    padding: 2rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .p-md-5 {
    padding: 2rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .px-sm-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .px-md-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .px-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .px-sm-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .px-md-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .px-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .px-sm-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .px-md-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .px-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .px-sm-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .px-md-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .px-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .px-sm-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .px-md-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .px-5 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .px-sm-5 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .px-md-5 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .py-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .py-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .py-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .py-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .py-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .py-sm-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .py-md-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .pt-0 {
    padding-top: 0 !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .pt-sm-0 {
    padding-top: 0 !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .pt-md-0 {
    padding-top: 0 !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .pt-1 {
    padding-top: 0.25rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .pt-2 {
    padding-top: 0.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .pt-3 {
    padding-top: 1rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .pt-md-3 {
    padding-top: 1rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .pt-4 {
    padding-top: 1.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .pt-5 {
    padding-top: 2rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .pt-sm-5 {
    padding-top: 2rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .pt-md-5 {
    padding-top: 2rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .pr-0 {
    padding-right: 0 !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .pr-sm-0 {
    padding-right: 0 !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .pr-md-0 {
    padding-right: 0 !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .pr-1 {
    padding-right: 0.25rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .pr-sm-1 {
    padding-right: 0.25rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .pr-md-1 {
    padding-right: 0.25rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .pr-2 {
    padding-right: 0.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .pr-sm-2 {
    padding-right: 0.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .pr-md-2 {
    padding-right: 0.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .pr-3 {
    padding-right: 1rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .pr-sm-3 {
    padding-right: 1rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .pr-md-3 {
    padding-right: 1rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .pr-4 {
    padding-right: 1.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .pr-sm-4 {
    padding-right: 1.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .pr-md-4 {
    padding-right: 1.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .pr-5 {
    padding-right: 2rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .pr-sm-5 {
    padding-right: 2rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .pr-md-5 {
    padding-right: 2rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .pb-0 {
    padding-bottom: 0 !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .pb-1 {
    padding-bottom: 0.25rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .pb-2 {
    padding-bottom: 0.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .pb-3 {
    padding-bottom: 1rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .pb-4 {
    padding-bottom: 1.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .pb-5 {
    padding-bottom: 2rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .pb-sm-5 {
    padding-bottom: 2rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .pb-md-5 {
    padding-bottom: 2rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .pl-0 {
    padding-left: 0 !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .pl-sm-0 {
    padding-left: 0 !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .pl-md-0 {
    padding-left: 0 !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .pl-1 {
    padding-left: 0.25rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .pl-sm-1 {
    padding-left: 0.25rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .pl-md-1 {
    padding-left: 0.25rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .pl-2 {
    padding-left: 0.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .pl-sm-2 {
    padding-left: 0.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .pl-md-2 {
    padding-left: 0.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .pl-3 {
    padding-left: 1rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .pl-sm-3 {
    padding-left: 1rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .pl-md-3 {
    padding-left: 1rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .pl-4 {
    padding-left: 1.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .pl-sm-4 {
    padding-left: 1.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .pl-md-4 {
    padding-left: 1.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .pl-5 {
    padding-left: 2rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .pl-sm-5 {
    padding-left: 2rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .pl-md-5 {
    padding-left: 2rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .m-0 {
    margin: 0 !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .m-1 {
    margin: 0.25rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .m-sm-1 {
    margin: 0.25rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .m-md-1 {
    margin: 0.25rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .m-2 {
    margin: 0.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .m-sm-2 {
    margin: 0.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .m-md-2 {
    margin: 0.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .m-3 {
    margin: 1rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .m-sm-3 {
    margin: 1rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .m-md-3 {
    margin: 1rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .m-4 {
    margin: 1.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .m-sm-4 {
    margin: 1.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .m-md-4 {
    margin: 1.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .m-5 {
    margin: 2rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .m-sm-5 {
    margin: 2rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .m-md-5 {
    margin: 2rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .mx-sm-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .mx-md-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .mx-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .mx-sm-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .mx-md-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .mx-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .mx-sm-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .mx-md-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .mx-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .mx-sm-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .mx-md-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .mx-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .mx-sm-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .mx-md-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .mx-5 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .mx-sm-5 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .mx-md-5 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .my-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .my-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .my-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .my-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .my-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .my-sm-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .my-md-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .mt-0 {
    margin-top: 0 !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .mt-sm-0 {
    margin-top: 0 !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .mt-md-0 {
    margin-top: 0 !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .mt-1 {
    margin-top: 0.25rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .mt-2 {
    margin-top: 0.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .mt-3 {
    margin-top: 1rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .mt-md-3 {
    margin-top: 1rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .mt-4 {
    margin-top: 1.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .mt-5 {
    margin-top: 2rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .mt-sm-5 {
    margin-top: 2rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .mt-md-5 {
    margin-top: 2rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .mr-0 {
    margin-right: 0 !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .mr-sm-0 {
    margin-right: 0 !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .mr-md-0 {
    margin-right: 0 !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .mr-1 {
    margin-right: 0.25rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .mr-sm-1 {
    margin-right: 0.25rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .mr-md-1 {
    margin-right: 0.25rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .mr-2 {
    margin-right: 0.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .mr-sm-2 {
    margin-right: 0.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .mr-md-2 {
    margin-right: 0.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .mr-3 {
    margin-right: 1rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .mr-sm-3 {
    margin-right: 1rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .mr-md-3 {
    margin-right: 1rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .mr-4 {
    margin-right: 1.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .mr-sm-4 {
    margin-right: 1.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .mr-md-4 {
    margin-right: 1.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .mr-5 {
    margin-right: 2rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .mr-sm-5 {
    margin-right: 2rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .mr-md-5 {
    margin-right: 2rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .mb-0 {
    margin-bottom: 0 !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .mb-1 {
    margin-bottom: 0.25rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .mb-2 {
    margin-bottom: 0.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .mb-3 {
    margin-bottom: 1rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .mb-4 {
    margin-bottom: 1.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .mb-5 {
    margin-bottom: 2rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .mb-sm-5 {
    margin-bottom: 2rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .mb-md-5 {
    margin-bottom: 2rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .ml-0 {
    margin-left: 0 !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .ml-sm-0 {
    margin-left: 0 !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .ml-md-0 {
    margin-left: 0 !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .ml-1 {
    margin-left: 0.25rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .ml-sm-1 {
    margin-left: 0.25rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .ml-md-1 {
    margin-left: 0.25rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .ml-2 {
    margin-left: 0.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .ml-sm-2 {
    margin-left: 0.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .ml-md-2 {
    margin-left: 0.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .ml-3 {
    margin-left: 1rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .ml-sm-3 {
    margin-left: 1rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .ml-md-3 {
    margin-left: 1rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .ml-4 {
    margin-left: 1.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .ml-sm-4 {
    margin-left: 1.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .ml-md-4 {
    margin-left: 1.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .ml-5 {
    margin-left: 2rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .ml-sm-5 {
    margin-left: 2rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .ml-md-5 {
    margin-left: 2rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .p-0 {
    padding: 0 !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .p-sm-0 {
    padding: 0 !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .p-md-0 {
    padding: 0 !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .p-1 {
    padding: 0.25rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .p-sm-1 {
    padding: 0.25rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .p-md-1 {
    padding: 0.25rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .p-2 {
    padding: 0.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .p-sm-2 {
    padding: 0.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .p-md-2 {
    padding: 0.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .p-3 {
    padding: 1rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .p-sm-3 {
    padding: 1rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .p-md-3 {
    padding: 1rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .p-4 {
    padding: 1.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .p-sm-4 {
    padding: 1.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .p-md-4 {
    padding: 1.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .p-5 {
    padding: 2rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .p-sm-5 {
    padding: 2rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .p-md-5 {
    padding: 2rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .px-sm-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .px-md-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .px-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .px-sm-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .px-md-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .px-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .px-sm-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .px-md-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .px-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .px-sm-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .px-md-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .px-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .px-sm-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .px-md-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .px-5 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .px-sm-5 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .px-md-5 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .py-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .py-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .py-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .py-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .py-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .py-sm-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .py-md-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .pt-0 {
    padding-top: 0 !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .pt-sm-0 {
    padding-top: 0 !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .pt-md-0 {
    padding-top: 0 !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .pt-1 {
    padding-top: 0.25rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .pt-2 {
    padding-top: 0.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .pt-3 {
    padding-top: 1rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .pt-md-3 {
    padding-top: 1rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .pt-4 {
    padding-top: 1.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .pt-5 {
    padding-top: 2rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .pt-sm-5 {
    padding-top: 2rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .pt-md-5 {
    padding-top: 2rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .pr-0 {
    padding-right: 0 !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .pr-sm-0 {
    padding-right: 0 !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .pr-md-0 {
    padding-right: 0 !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .pr-1 {
    padding-right: 0.25rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .pr-sm-1 {
    padding-right: 0.25rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .pr-md-1 {
    padding-right: 0.25rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .pr-2 {
    padding-right: 0.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .pr-sm-2 {
    padding-right: 0.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .pr-md-2 {
    padding-right: 0.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .pr-3 {
    padding-right: 1rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .pr-sm-3 {
    padding-right: 1rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .pr-md-3 {
    padding-right: 1rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .pr-4 {
    padding-right: 1.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .pr-sm-4 {
    padding-right: 1.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .pr-md-4 {
    padding-right: 1.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .pr-5 {
    padding-right: 2rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .pr-sm-5 {
    padding-right: 2rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .pr-md-5 {
    padding-right: 2rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .pb-0 {
    padding-bottom: 0 !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .pb-1 {
    padding-bottom: 0.25rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .pb-2 {
    padding-bottom: 0.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .pb-3 {
    padding-bottom: 1rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .pb-4 {
    padding-bottom: 1.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .pb-5 {
    padding-bottom: 2rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .pb-sm-5 {
    padding-bottom: 2rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .pb-md-5 {
    padding-bottom: 2rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .pl-0 {
    padding-left: 0 !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .pl-sm-0 {
    padding-left: 0 !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .pl-md-0 {
    padding-left: 0 !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .pl-1 {
    padding-left: 0.25rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .pl-sm-1 {
    padding-left: 0.25rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .pl-md-1 {
    padding-left: 0.25rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .pl-2 {
    padding-left: 0.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .pl-sm-2 {
    padding-left: 0.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .pl-md-2 {
    padding-left: 0.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .pl-3 {
    padding-left: 1rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .pl-sm-3 {
    padding-left: 1rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .pl-md-3 {
    padding-left: 1rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .pl-4 {
    padding-left: 1.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .pl-sm-4 {
    padding-left: 1.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .pl-md-4 {
    padding-left: 1.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .pl-5 {
    padding-left: 2rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .pl-sm-5 {
    padding-left: 2rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .pl-md-5 {
    padding-left: 2rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .m-0 {
    margin: 0 !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .m-1 {
    margin: 0.25rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .m-sm-1 {
    margin: 0.25rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .m-md-1 {
    margin: 0.25rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .m-2 {
    margin: 0.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .m-sm-2 {
    margin: 0.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .m-md-2 {
    margin: 0.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .m-3 {
    margin: 1rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .m-sm-3 {
    margin: 1rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .m-md-3 {
    margin: 1rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .m-4 {
    margin: 1.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .m-sm-4 {
    margin: 1.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .m-md-4 {
    margin: 1.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .m-5 {
    margin: 2rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .m-sm-5 {
    margin: 2rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .m-md-5 {
    margin: 2rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .mx-sm-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .mx-md-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .mx-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .mx-sm-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .mx-md-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .mx-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .mx-sm-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .mx-md-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .mx-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .mx-sm-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .mx-md-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .mx-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .mx-sm-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .mx-md-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .mx-5 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .mx-sm-5 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .mx-md-5 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .my-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .my-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .my-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .my-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .my-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .my-sm-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .my-md-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .mt-0 {
    margin-top: 0 !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .mt-sm-0 {
    margin-top: 0 !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .mt-md-0 {
    margin-top: 0 !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .mt-1 {
    margin-top: 0.25rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .mt-2 {
    margin-top: 0.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .mt-3 {
    margin-top: 1rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .mt-md-3 {
    margin-top: 1rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .mt-4 {
    margin-top: 1.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .mt-5 {
    margin-top: 2rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .mt-sm-5 {
    margin-top: 2rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .mt-md-5 {
    margin-top: 2rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .mr-0 {
    margin-right: 0 !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .mr-sm-0 {
    margin-right: 0 !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .mr-md-0 {
    margin-right: 0 !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .mr-1 {
    margin-right: 0.25rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .mr-sm-1 {
    margin-right: 0.25rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .mr-md-1 {
    margin-right: 0.25rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .mr-2 {
    margin-right: 0.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .mr-sm-2 {
    margin-right: 0.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .mr-md-2 {
    margin-right: 0.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .mr-3 {
    margin-right: 1rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .mr-sm-3 {
    margin-right: 1rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .mr-md-3 {
    margin-right: 1rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .mr-4 {
    margin-right: 1.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .mr-sm-4 {
    margin-right: 1.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .mr-md-4 {
    margin-right: 1.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .mr-5 {
    margin-right: 2rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .mr-sm-5 {
    margin-right: 2rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .mr-md-5 {
    margin-right: 2rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .mb-0 {
    margin-bottom: 0 !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .mb-1 {
    margin-bottom: 0.25rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .mb-2 {
    margin-bottom: 0.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .mb-3 {
    margin-bottom: 1rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .mb-4 {
    margin-bottom: 1.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .mb-5 {
    margin-bottom: 2rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .mb-sm-5 {
    margin-bottom: 2rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .mb-md-5 {
    margin-bottom: 2rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .ml-0 {
    margin-left: 0 !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .ml-sm-0 {
    margin-left: 0 !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .ml-md-0 {
    margin-left: 0 !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .ml-1 {
    margin-left: 0.25rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .ml-sm-1 {
    margin-left: 0.25rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .ml-md-1 {
    margin-left: 0.25rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .ml-2 {
    margin-left: 0.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .ml-sm-2 {
    margin-left: 0.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .ml-md-2 {
    margin-left: 0.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .ml-3 {
    margin-left: 1rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .ml-sm-3 {
    margin-left: 1rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .ml-md-3 {
    margin-left: 1rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .ml-4 {
    margin-left: 1.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .ml-sm-4 {
    margin-left: 1.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .ml-md-4 {
    margin-left: 1.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .ml-5 {
    margin-left: 2rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .ml-sm-5 {
    margin-left: 2rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .ml-md-5 {
    margin-left: 2rem !important;
  }
}
@media (min-width: 1200px) {
  .p-0 {
    padding: 0 !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .p-sm-0 {
    padding: 0 !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .p-md-0 {
    padding: 0 !important;
  }
}
@media (min-width: 1200px) {
  .p-1 {
    padding: 0.25rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .p-sm-1 {
    padding: 0.25rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .p-md-1 {
    padding: 0.25rem !important;
  }
}
@media (min-width: 1200px) {
  .p-2 {
    padding: 0.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .p-sm-2 {
    padding: 0.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .p-md-2 {
    padding: 0.5rem !important;
  }
}
@media (min-width: 1200px) {
  .p-3 {
    padding: 1rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .p-sm-3 {
    padding: 1rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .p-md-3 {
    padding: 1rem !important;
  }
}
@media (min-width: 1200px) {
  .p-4 {
    padding: 1.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .p-sm-4 {
    padding: 1.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .p-md-4 {
    padding: 1.5rem !important;
  }
}
@media (min-width: 1200px) {
  .p-5 {
    padding: 2rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .p-sm-5 {
    padding: 2rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .p-md-5 {
    padding: 2rem !important;
  }
}
@media (min-width: 1200px) {
  .px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .px-sm-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .px-md-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
@media (min-width: 1200px) {
  .px-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .px-sm-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .px-md-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
}
@media (min-width: 1200px) {
  .px-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .px-sm-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .px-md-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
}
@media (min-width: 1200px) {
  .px-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .px-sm-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .px-md-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}
@media (min-width: 1200px) {
  .px-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .px-sm-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .px-md-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
}
@media (min-width: 1200px) {
  .px-5 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .px-sm-5 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .px-md-5 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
}
@media (min-width: 1200px) {
  .py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}
@media (min-width: 1200px) {
  .py-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
}
@media (min-width: 1200px) {
  .py-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
}
@media (min-width: 1200px) {
  .py-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
}
@media (min-width: 1200px) {
  .py-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
}
@media (min-width: 1200px) {
  .py-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .py-sm-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .py-md-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
}
@media (min-width: 1200px) {
  .pt-0 {
    padding-top: 0 !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .pt-sm-0 {
    padding-top: 0 !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .pt-md-0 {
    padding-top: 0 !important;
  }
}
@media (min-width: 1200px) {
  .pt-1 {
    padding-top: 0.25rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
}
@media (min-width: 1200px) {
  .pt-2 {
    padding-top: 0.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
}
@media (min-width: 1200px) {
  .pt-3 {
    padding-top: 1rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .pt-md-3 {
    padding-top: 1rem !important;
  }
}
@media (min-width: 1200px) {
  .pt-4 {
    padding-top: 1.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
}
@media (min-width: 1200px) {
  .pt-5 {
    padding-top: 2rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .pt-sm-5 {
    padding-top: 2rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .pt-md-5 {
    padding-top: 2rem !important;
  }
}
@media (min-width: 1200px) {
  .pr-0 {
    padding-right: 0 !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .pr-sm-0 {
    padding-right: 0 !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .pr-md-0 {
    padding-right: 0 !important;
  }
}
@media (min-width: 1200px) {
  .pr-1 {
    padding-right: 0.25rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .pr-sm-1 {
    padding-right: 0.25rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .pr-md-1 {
    padding-right: 0.25rem !important;
  }
}
@media (min-width: 1200px) {
  .pr-2 {
    padding-right: 0.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .pr-sm-2 {
    padding-right: 0.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .pr-md-2 {
    padding-right: 0.5rem !important;
  }
}
@media (min-width: 1200px) {
  .pr-3 {
    padding-right: 1rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .pr-sm-3 {
    padding-right: 1rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .pr-md-3 {
    padding-right: 1rem !important;
  }
}
@media (min-width: 1200px) {
  .pr-4 {
    padding-right: 1.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .pr-sm-4 {
    padding-right: 1.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .pr-md-4 {
    padding-right: 1.5rem !important;
  }
}
@media (min-width: 1200px) {
  .pr-5 {
    padding-right: 2rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .pr-sm-5 {
    padding-right: 2rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .pr-md-5 {
    padding-right: 2rem !important;
  }
}
@media (min-width: 1200px) {
  .pb-0 {
    padding-bottom: 0 !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
}
@media (min-width: 1200px) {
  .pb-1 {
    padding-bottom: 0.25rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
}
@media (min-width: 1200px) {
  .pb-2 {
    padding-bottom: 0.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
}
@media (min-width: 1200px) {
  .pb-3 {
    padding-bottom: 1rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
}
@media (min-width: 1200px) {
  .pb-4 {
    padding-bottom: 1.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
}
@media (min-width: 1200px) {
  .pb-5 {
    padding-bottom: 2rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .pb-sm-5 {
    padding-bottom: 2rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .pb-md-5 {
    padding-bottom: 2rem !important;
  }
}
@media (min-width: 1200px) {
  .pl-0 {
    padding-left: 0 !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .pl-sm-0 {
    padding-left: 0 !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .pl-md-0 {
    padding-left: 0 !important;
  }
}
@media (min-width: 1200px) {
  .pl-1 {
    padding-left: 0.25rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .pl-sm-1 {
    padding-left: 0.25rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .pl-md-1 {
    padding-left: 0.25rem !important;
  }
}
@media (min-width: 1200px) {
  .pl-2 {
    padding-left: 0.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .pl-sm-2 {
    padding-left: 0.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .pl-md-2 {
    padding-left: 0.5rem !important;
  }
}
@media (min-width: 1200px) {
  .pl-3 {
    padding-left: 1rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .pl-sm-3 {
    padding-left: 1rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .pl-md-3 {
    padding-left: 1rem !important;
  }
}
@media (min-width: 1200px) {
  .pl-4 {
    padding-left: 1.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .pl-sm-4 {
    padding-left: 1.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .pl-md-4 {
    padding-left: 1.5rem !important;
  }
}
@media (min-width: 1200px) {
  .pl-5 {
    padding-left: 2rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .pl-sm-5 {
    padding-left: 2rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .pl-md-5 {
    padding-left: 2rem !important;
  }
}
@media (min-width: 1200px) {
  .m-0 {
    margin: 0 !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
}
@media (min-width: 1200px) {
  .m-1 {
    margin: 0.25rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .m-sm-1 {
    margin: 0.25rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .m-md-1 {
    margin: 0.25rem !important;
  }
}
@media (min-width: 1200px) {
  .m-2 {
    margin: 0.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .m-sm-2 {
    margin: 0.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .m-md-2 {
    margin: 0.5rem !important;
  }
}
@media (min-width: 1200px) {
  .m-3 {
    margin: 1rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .m-sm-3 {
    margin: 1rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .m-md-3 {
    margin: 1rem !important;
  }
}
@media (min-width: 1200px) {
  .m-4 {
    margin: 1.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .m-sm-4 {
    margin: 1.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .m-md-4 {
    margin: 1.5rem !important;
  }
}
@media (min-width: 1200px) {
  .m-5 {
    margin: 2rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .m-sm-5 {
    margin: 2rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .m-md-5 {
    margin: 2rem !important;
  }
}
@media (min-width: 1200px) {
  .mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .mx-sm-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .mx-md-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
@media (min-width: 1200px) {
  .mx-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .mx-sm-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .mx-md-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }
}
@media (min-width: 1200px) {
  .mx-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .mx-sm-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .mx-md-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
}
@media (min-width: 1200px) {
  .mx-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .mx-sm-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .mx-md-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
}
@media (min-width: 1200px) {
  .mx-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .mx-sm-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .mx-md-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
}
@media (min-width: 1200px) {
  .mx-5 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .mx-sm-5 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .mx-md-5 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }
}
@media (min-width: 1200px) {
  .my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}
@media (min-width: 1200px) {
  .my-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
}
@media (min-width: 1200px) {
  .my-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
}
@media (min-width: 1200px) {
  .my-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
}
@media (min-width: 1200px) {
  .my-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
}
@media (min-width: 1200px) {
  .my-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .my-sm-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .my-md-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
}
@media (min-width: 1200px) {
  .mt-0 {
    margin-top: 0 !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .mt-sm-0 {
    margin-top: 0 !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .mt-md-0 {
    margin-top: 0 !important;
  }
}
@media (min-width: 1200px) {
  .mt-1 {
    margin-top: 0.25rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
}
@media (min-width: 1200px) {
  .mt-2 {
    margin-top: 0.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
}
@media (min-width: 1200px) {
  .mt-3 {
    margin-top: 1rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .mt-md-3 {
    margin-top: 1rem !important;
  }
}
@media (min-width: 1200px) {
  .mt-4 {
    margin-top: 1.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
}
@media (min-width: 1200px) {
  .mt-5 {
    margin-top: 2rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .mt-sm-5 {
    margin-top: 2rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .mt-md-5 {
    margin-top: 2rem !important;
  }
}
@media (min-width: 1200px) {
  .mr-0 {
    margin-right: 0 !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .mr-sm-0 {
    margin-right: 0 !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .mr-md-0 {
    margin-right: 0 !important;
  }
}
@media (min-width: 1200px) {
  .mr-1 {
    margin-right: 0.25rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .mr-sm-1 {
    margin-right: 0.25rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .mr-md-1 {
    margin-right: 0.25rem !important;
  }
}
@media (min-width: 1200px) {
  .mr-2 {
    margin-right: 0.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .mr-sm-2 {
    margin-right: 0.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .mr-md-2 {
    margin-right: 0.5rem !important;
  }
}
@media (min-width: 1200px) {
  .mr-3 {
    margin-right: 1rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .mr-sm-3 {
    margin-right: 1rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .mr-md-3 {
    margin-right: 1rem !important;
  }
}
@media (min-width: 1200px) {
  .mr-4 {
    margin-right: 1.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .mr-sm-4 {
    margin-right: 1.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .mr-md-4 {
    margin-right: 1.5rem !important;
  }
}
@media (min-width: 1200px) {
  .mr-5 {
    margin-right: 2rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .mr-sm-5 {
    margin-right: 2rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .mr-md-5 {
    margin-right: 2rem !important;
  }
}
@media (min-width: 1200px) {
  .mb-0 {
    margin-bottom: 0 !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
}
@media (min-width: 1200px) {
  .mb-1 {
    margin-bottom: 0.25rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
}
@media (min-width: 1200px) {
  .mb-2 {
    margin-bottom: 0.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
}
@media (min-width: 1200px) {
  .mb-3 {
    margin-bottom: 1rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
}
@media (min-width: 1200px) {
  .mb-4 {
    margin-bottom: 1.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
}
@media (min-width: 1200px) {
  .mb-5 {
    margin-bottom: 2rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .mb-sm-5 {
    margin-bottom: 2rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .mb-md-5 {
    margin-bottom: 2rem !important;
  }
}
@media (min-width: 1200px) {
  .ml-0 {
    margin-left: 0 !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .ml-sm-0 {
    margin-left: 0 !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .ml-md-0 {
    margin-left: 0 !important;
  }
}
@media (min-width: 1200px) {
  .ml-1 {
    margin-left: 0.25rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .ml-sm-1 {
    margin-left: 0.25rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .ml-md-1 {
    margin-left: 0.25rem !important;
  }
}
@media (min-width: 1200px) {
  .ml-2 {
    margin-left: 0.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .ml-sm-2 {
    margin-left: 0.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .ml-md-2 {
    margin-left: 0.5rem !important;
  }
}
@media (min-width: 1200px) {
  .ml-3 {
    margin-left: 1rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .ml-sm-3 {
    margin-left: 1rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .ml-md-3 {
    margin-left: 1rem !important;
  }
}
@media (min-width: 1200px) {
  .ml-4 {
    margin-left: 1.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .ml-sm-4 {
    margin-left: 1.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .ml-md-4 {
    margin-left: 1.5rem !important;
  }
}
@media (min-width: 1200px) {
  .ml-5 {
    margin-left: 2rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .ml-sm-5 {
    margin-left: 2rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .ml-md-5 {
    margin-left: 2rem !important;
  }
}
.text-center {
  text-align: center;
}

.text-start {
  text-align: start;
}

.text-end {
  text-align: end;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

@media (min-width: 576px) {
  .text-sm-center {
    text-align: center;
  }
  .text-sm-start {
    text-align: start;
  }
  .text-sm-end {
    text-align: end;
  }
  .text-sm-left {
    text-align: left;
  }
  .text-sm-right {
    text-align: right;
  }
  .text-sm-justify {
    text-align: justify;
  }
}
@media (min-width: 768px) {
  .text-md-center {
    text-align: center;
  }
  .text-md-start {
    text-align: start;
  }
  .text-md-end {
    text-align: end;
  }
  .text-md-left {
    text-align: left;
  }
  .text-md-right {
    text-align: right;
  }
  .text-md-justify {
    text-align: justify;
  }
}
@media (min-width: 992px) {
  .text-lg-center {
    text-align: center;
  }
  .text-lg-start {
    text-align: start;
  }
  .text-lg-end {
    text-align: end;
  }
  .text-lg-left {
    text-align: left;
  }
  .text-lg-right {
    text-align: right;
  }
  .text-lg-justify {
    text-align: justify;
  }
}
@media (min-width: 1200px) {
  .text-xl-center {
    text-align: center;
  }
  .text-xl-start {
    text-align: start;
  }
  .text-xl-end {
    text-align: end;
  }
  .text-xl-left {
    text-align: left;
  }
  .text-xl-right {
    text-align: right;
  }
  .text-xl-justify {
    text-align: justify;
  }
}
.visible {
  display: block !important;
}

.invisible {
  display: none !important;
}

@media (max-width: 575px) {
  .visible-xs {
    display: block !important;
  }
  .invisible-xs {
    display: none !important;
  }
}
@media (min-width: 576px) {
  .visible-sm {
    display: block !important;
  }
  .invisible-sm {
    display: none !important;
  }
}
@media (min-width: 768px) {
  .visible-md {
    display: block !important;
  }
  .invisible-md {
    display: none !important;
  }
}
@media (min-width: 992px) {
  .visible-lg {
    display: block !important;
  }
  .invisible-lg {
    display: none !important;
  }
}
@media (min-width: 1200px) {
  .visible-xl {
    display: block !important;
  }
  .invisible-xl {
    display: none !important;
  }
}
.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.invisible {
  visibility: hidden !important;
}

.visible {
  visibility: visible !important;
}

@media (max-width: 575px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 576px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xxl-none {
    display: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
}
#member-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2rem;
}
#member-header .server-time p {
  margin: 0;
  font-size: 0.786rem;
}
#member-header .header-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
#member-header .header-right .divider {
  height: 19px;
  width: 1px;
  background-color: #585858;
  margin: 0 30px;
}
#member-header .header-right .sponsor p {
  margin: 0;
  text-transform: capitalize;
}
#member-header .header-right .user_logged_in {
  display: flex;
  align-items: center;
  cursor: pointer;
}
#member-header .header-right .user_logged_in .photo img {
  width: 46px;
  height: 46px;
  border-radius: 50%;
}
#member-header .header-right .user_logged_in .info {
  padding-left: 1rem;
}
#member-header .header-right .user_logged_in .info h4 {
  font-size: 1rem;
  font-family: "Work Sans", sans-serif;
  margin: 0;
  text-transform: capitalize;
}
#member-header .header-right .user_logged_in .info p {
  color: #959699;
  font-size: 0.857rem;
  margin: 3px 0 0 0;
}

@media screen and (max-width: 767px) {
  #member-header .server-time {
    display: none;
  }
  #member-header .header-right .sponsor {
    display: none;
  }
}
.wrapper {
  display: flex;
  width: 100%;
  background-color: rgb(241, 245, 249);
  height: 100vh;
  overflow: hidden;
}
.wrapper .left-side {
  height: 100vh;
  width: 280px;
  padding: 0;
  color: rgb(255, 255, 255);
  border-right: 1px solid #000000;
  background-color: #000;
}
.wrapper .left-side > a {
  border-right: 1px solid #434343;
  border-bottom: 1px solid #434343;
  display: block;
  padding: 10px 23px;
  border-bottom-right-radius: 30px;
  background-color: #1E1E1E;
  padding-left: 20px;
  text-align: center;
}
.wrapper .left-side > a img {
  width: 60px;
}
.wrapper .left-side .side-bar {
  border-right: 1px solid #434343;
  border-top: 1px solid #434343;
  border-top-right-radius: 30px;
  display: flex;
  flex-direction: column;
  height: 100%;
  list-style: none;
  margin-top: -1px;
  padding-left: 20px;
  padding-top: 23px;
  background-color: #1E1E1E;
}
.wrapper .left-side .side-bar li {
  margin-bottom: 24px;
}
.wrapper .left-side .side-bar li a {
  display: flex;
  text-decoration: none;
  align-items: center;
}
.wrapper .left-side .side-bar li a i {
  font-size: 1.5rem;
  color: white;
  margin-right: 0.9rem;
}
.wrapper .left-side .side-bar li a span {
  font-size: 0.875rem;
  color: white;
}
.wrapper .left-side .side-bar li a.active, .wrapper .left-side .side-bar li a:hover {
  border-right: 3px solid #FFBE18;
}
.wrapper .left-side .side-bar li a.active i,
.wrapper .left-side .side-bar li a.active span, .wrapper .left-side .side-bar li a:hover i,
.wrapper .left-side .side-bar li a:hover span {
  color: #FFBE18;
}
.wrapper .right-side {
  width: 100%;
  background-color: #000;
}
.wrapper .right-side .right-side-contents {
  width: 100%;
  padding: 0;
}
.wrapper .right-side .right-side-contents .page-content {
  overflow-y: scroll;
  overflow-x: hidden;
  height: calc(100vh - 90px);
  padding: 0 28px 100px 28px;
  position: relative;
}
.wrapper .right-side .content-header {
  display: flex;
  justify-content: space-between;
  background: #000000;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  align-items: center;
  padding: 0.8rem 2rem;
  border-bottom-left-radius: 20px;
}
.wrapper .right-side .content-header .right {
  display: flex;
  justify-content: flex-end;
}
.wrapper .right-side .content-header .right .button {
  margin-left: 0.5rem;
}
.wrapper .right-side .content-header h1 {
  font-size: 1.5rem;
  margin: 0;
  color: #FFBE18;
  font-weight: bold;
}
.wrapper .right-side > .content {
  padding: 2rem;
  position: relative;
}
.wrapper #overlaymenu {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  z-index: 2;
  display: none;
}
.wrapper #overlaymenu.active {
  display: block;
}

.d-none {
  display: none;
}

#menubutton {
  display: none;
}

@media screen and (max-width: 767px) {
  .wrapper {
    display: block;
  }
  .wrapper .left-side {
    display: none;
    opacity: 0;
    /* Start with opacity set to 0 */
  }
  .wrapper .left-side.active {
    position: absolute;
    top: 0 !important;
    left: 0 !important;
    z-index: 10;
    display: block;
    opacity: 1;
    /* Transition the opacity to 1 for a fade-in effect */
    transition: opacity 0.3s ease;
    /* Add a smooth transition */
  }
  .wrapper .right-side {
    padding-left: 0;
  }
  .wrapper .right-side > .content {
    padding: 1rem;
  }
  .wrapper .right-side .content-header {
    flex-direction: column;
    align-items: flex-start;
    padding: 0.5rem 1rem;
  }
  .wrapper .right-side .content-header h1 {
    font-weight: 600;
    font-size: 1.2rem;
    margin-bottom: 0.3rem;
  }
  #menubutton {
    display: block;
  }
}
.button {
  display: inline-block;
  vertical-align: middle;
  border: none;
  text-align: center;
  transition: all 0.4s ease-in-out;
  border: 1px solid transparent;
  font-size: 14px;
  font-weight: 600;
  padding: 8px 10px;
  border-radius: 5px;
  color: #FFBE18;
  cursor: pointer;
}
.button.hover-on-dark:hover {
  color: #181818;
  border-color: #8a95a3;
}
.button .button-small {
  font-size: 0.929rem;
}

.button-long {
  min-width: 130px;
}

.button-dark {
  background-color: #000000;
  color: #FFF;
}
.button-dark:hover {
  background-color: transparent;
  border-color: #000000;
  color: #000000;
}

.button-icon {
  position: relative;
  display: inline-flex;
  justify-content: space-between;
  padding: 8px 0px 8px 10px;
  white-space: nowrap;
  align-items: center;
}
.button-icon span {
  width: 30px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 700;
}
.button-icon.icon-right span {
  margin-left: 8px;
}
.button-icon.icon-left {
  flex-direction: row-reverse;
  justify-content: center;
  padding: 0.6rem 1rem 0.6rem 1rem;
}
.button-icon.icon-left span,
.button-icon.icon-left i {
  margin-left: 0;
  margin-right: 0.4rem;
}
.button-icon.button-dark span {
  background-color: #FFBE18;
  color: #000000;
}

.button-white {
  background-color: rgb(255, 255, 255);
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.0431372549);
  color: #FFBE18;
}
.button-white:hover {
  background-color: #f1f1f1;
}

.btn:focus {
  outline: none;
  box-shadow: none;
}

.button-primary {
  background-color: #FFBE18;
  border-radius: 80px;
  color: #142129 !important;
  position: relative;
  padding: 6px 30px;
  font-size: 16px;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
  border: 3px solid rgba(255, 255, 255, 0.5);
  box-shadow: -1px -1px 11px 2px rgba(255, 190, 24, 0.51);
  -webkit-box-shadow: -1px -1px 11px 2px rgba(255, 190, 24, 0.51);
  -moz-box-shadow: -1px -1px 11px 2px rgba(255, 190, 24, 0.51);
  overflow: hidden;
  transition: all 0.4s ease;
  cursor: pointer;
}
.button-primary:hover, .button-primary:focus {
  color: #142129 !important;
  opacity: 0.8;
}
.button-primary:disabled {
  opacity: 0.5;
  cursor: none;
}
.button-primary::after {
  content: "";
  width: 100%;
  position: absolute;
  height: 100%;
  bottom: -24px;
  left: 0;
  background-color: rgba(0, 0, 0, 0.15);
}

.button-secondary {
  background-color: #181818;
  color: #ffffff;
  font-weight: 600;
  border: 1px solid #181818;
  border-color: #707070;
}
.button-secondary span {
  font-size: 0.9rem;
}
.button-secondary:hover {
  background-color: #181818;
  color: #FFBE18;
  opacity: 1;
}

.dropdown-button::after {
  border: none !important;
}

.button-outline-secondary {
  border-color: #181818;
  color: #181818;
}
.button-outline-secondary:hover {
  color: #FFF;
  background-color: #181818;
  border: 1px solid #181818;
}

.button-outline-primary {
  background-color: transparent;
  border-radius: 80px;
  color: #FFBE18;
  position: relative;
  padding: 6px 30px;
  font-size: 16px;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
  border: 3px solid rgba(255, 190, 24, 0.5);
  box-shadow: -1px -1px 11px 2px rgba(255, 190, 24, 0.51);
  -webkit-box-shadow: -1px -1px 11px 2px rgba(255, 190, 24, 0.51);
  -moz-box-shadow: -1px -1px 11px 2px rgba(255, 190, 24, 0.51);
  overflow: hidden;
  transition: all 0.4s ease;
  cursor: pointer;
}
.button-outline-primary:hover, .button-outline-primary:focus {
  color: #ffffff;
  opacity: 0.8;
}
.button-outline-primary:disabled {
  opacity: 0.5;
  cursor: none;
}
.button-outline-primary::after {
  content: "";
  width: 100%;
  position: absolute;
  height: 100%;
  bottom: -24px;
  left: 0;
  background-color: rgba(0, 0, 0, 0.15);
}

.button-fb {
  background-color: #3C5A9A;
  color: #FFF;
  font-weight: bold;
}
.button-fb:hover {
  background-color: transparent;
  border-color: #3C5A9A;
  color: #3C5A9A;
  opacity: 1;
}

.button-success {
  background-color: #181818;
  color: #FFF;
}
.button-success:hover {
  color: #FFF;
  opacity: 0.8;
}

.button-round {
  border-radius: 80px;
}

.button-block {
  width: 100%;
  display: block;
}

.button-outline-gray {
  background-color: #F5F5F5;
  border: 1px solid #707070;
  color: #292724;
  font-weight: bold;
}
.button-outline-gray:hover {
  color: #292724;
  background-color: #FFF;
}

.button-large-icon {
  display: flex;
  flex-direction: column;
  line-height: 3rem;
}
.button-large-icon .icon {
  font-size: 3rem;
}
.button-large-icon .label {
  font-size: 1.5rem;
}

.round-icon-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.round-icon-button:hover {
  text-decoration: none;
}
.round-icon-button .icon {
  width: 42px;
  height: 42px;
  border: 1px solid #FFF;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.round-icon-button .label {
  font-size: 0.929rem;
  color: #FFF;
  margin-top: 6px;
}

.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
  color: #000 !important;
}

.ant-form-vertical .ant-form-item-label {
  padding: 0 !important;
}

.forgot-password {
  text-align: right;
  margin-bottom: 1rem;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #FFF !important;
}

.icon-input {
  position: relative;
}
.icon-input .icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 16px;
}
.icon-input .icon svg {
  width: 20px;
  cursor: pointer;
  color: #FFBE18;
}
.icon-input .icon-right {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 16px;
}
.icon-input .icon-right svg {
  width: 20px;
  cursor: pointer;
  color: #FFBE18;
}
.icon-input .toggle-password {
  position: absolute;
  right: 14px;
  top: 16px;
}
.icon-input .toggle-password svg {
  width: 26px;
  color: rgba(255, 255, 255, 0.5);
}
.icon-input .form-control {
  padding-left: 52px;
}

.form-default label {
  color: #fff;
  margin-bottom: 10px;
}
.form-default .form-control {
  min-height: 60px;
  background-color: #161E26;
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: #FFF;
}
.form-default .form-select {
  min-height: 60px;
  background-color: #161E26;
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: #FFF;
}

.scrollbar {
  overflow-y: scroll;
}
.scrollbar::-webkit-scrollbar {
  width: 0.6rem;
  border-radius: 20px;
}
.scrollbar::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1) !important;
}
.scrollbar::-webkit-scrollbar-thumb {
  background-color: #FFBE18;
  border-radius: 20px;
}
.scrollbar::-moz-scrollbar {
  width: 0.6rem;
  border-radius: 20px;
}
.scrollbar::-moz-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1) !important;
}
.scrollbar::-moz-scrollbar-thumb {
  background-color: #FFBE18;
  border-radius: 20px;
}
.scrollbar::-ms-scrollbar {
  width: 0.6rem;
  border-radius: 20px;
}
.scrollbar::-ms-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1) !important;
}
.scrollbar::-ms-scrollbar-thumb {
  background-color: #FFBE18;
  border-radius: 20px;
}
.scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #FFBE18 rgba(0, 0, 0, 0.1);
}

.card-black {
  background-color: #0E0E0E;
  border-radius: 0.5rem;
}

.card-title {
  font-weight: 500;
  font-size: 1.286rem;
}

.card-backdrop {
  border-radius: 8px;
  background: #151517;
  backdrop-filter: blur(100px);
}

.card-bottom-shadow {
  position: relative;
}
.card-bottom-shadow::before {
  content: "";
  width: 97%;
  box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.0431372549);
  position: absolute;
  left: 0px;
  right: 0px;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  border-radius: 8px;
  bottom: -10px;
  background-color: #202020;
}

.card-body {
  position: relative;
}

.card-action-title {
  display: flex;
  justify-content: space-between;
}
.card-action-title .card-actions {
  display: flex;
  align-items: center;
}
.card-action-title .card-actions p {
  margin: 0 20px 0 0;
}
.card-action-title .card-actions p span {
  margin-right: 6px;
}

.ref-table {
  width: 100%;
  border-collapse: collapse;
  text-align: left;
}
.ref-table thead th {
  color: rgba(255, 255, 255, 0.6);
  padding-bottom: 10px;
}
.ref-table .ref-member {
  display: flex;
  align-items: center;
}
.ref-table .ref-member img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
}
.ref-table .ref-member p {
  margin: 0;
}
.ref-table tbody tr {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}
.ref-table tbody td {
  padding: 8px 4px;
}

.modal-footer-buttons {
  text-align: right;
}
.modal-footer-buttons button {
  margin-left: 10px;
}

.modal-theme.size-md .modal-dialog {
  max-width: 500px;
}
.modal-theme .modal-content {
  padding: 2px;
}
.modal-theme .modal-header {
  background-color: #212529;
  padding: 14px 30px;
  position: relative;
}
.modal-theme .modal-header.header-dark {
  margin: -2px;
}
.modal-theme .modal-header.header-dark .modal-title {
  color: #FFF;
}
.modal-theme .modal-header.header-dark .btn-close {
  filter: invert(100%);
}
.modal-theme .modal-header::after {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  position: absolute;
  bottom: 0;
  left: 0;
}
.modal-theme .modal-header .modal-title {
  font-size: 18px;
  font-weight: bold;
  color: #000;
}
.modal-theme.modal-small .modal-dialog {
  max-width: 550px;
}
.modal-theme.modal-lg .modal-dialog {
  max-width: 960px;
}
.modal-theme .modal-dialog {
  max-width: 800px;
}
.modal-theme .modal-body {
  padding: 2.2rem 2rem;
  background-color: #212529;
}
.modal-theme .modal-body img {
  width: 100%;
  border-radius: 0.5rem;
}

@media screen and (max-width: 767px) {
  .bg-img-modal .modal-header .title {
    bottom: 2.5%;
  }
  .modal-theme .modal-body {
    padding: 1.3rem;
  }
  .modal-theme.modal-thumbnail .modal-dialog iframe {
    height: 245px !important;
  }
}
.ham {
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  transition: transform 400ms;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 50px;
  height: 50px;
}

.hamRotate.active {
  transform: rotate(45deg);
}

.hamRotate180.active {
  transform: rotate(180deg);
}

.line {
  fill: none;
  transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
  stroke: #FFBE18;
  stroke-width: 5.5;
  stroke-linecap: round;
}

.ham1 .top {
  stroke-dasharray: 40 139;
}

.ham1 .bottom {
  stroke-dasharray: 40 180;
}

.ham1.active .top {
  stroke-dashoffset: -98px;
}

.ham1.active .bottom {
  stroke-dashoffset: -138px;
}

.ham2 .top {
  stroke-dasharray: 40 121;
}

.ham2 .bottom {
  stroke-dasharray: 40 121;
}

.ham2.active .top {
  stroke-dashoffset: -102px;
}

.ham2.active .bottom {
  stroke-dashoffset: -102px;
}

.ham3 .top {
  stroke-dasharray: 40 130;
}

.ham3 .middle {
  stroke-dasharray: 40 140;
}

.ham3 .bottom {
  stroke-dasharray: 40 205;
}

.ham3.active .top {
  stroke-dasharray: 75 130;
  stroke-dashoffset: -63px;
}

.ham3.active .middle {
  stroke-dashoffset: -102px;
}

.ham3.active .bottom {
  stroke-dasharray: 110 205;
  stroke-dashoffset: -86px;
}

.ham4 .top {
  stroke-dasharray: 40 121;
}

.ham4 .bottom {
  stroke-dasharray: 40 121;
}

.ham4.active .top {
  stroke-dashoffset: -68px;
}

.ham4.active .bottom {
  stroke-dashoffset: -68px;
}

.ham5 .top {
  stroke-dasharray: 40 82;
}

.ham5 .bottom {
  stroke-dasharray: 40 82;
}

.ham5.active .top {
  stroke-dasharray: 14 82;
  stroke-dashoffset: -72px;
}

.ham5.active .bottom {
  stroke-dasharray: 14 82;
  stroke-dashoffset: -72px;
}

.ham6 .top {
  stroke-dasharray: 40 172;
}

.ham6 .middle {
  stroke-dasharray: 40 111;
}

.ham6 .bottom {
  stroke-dasharray: 40 172;
}

.ham6.active .top {
  stroke-dashoffset: -132px;
}

.ham6.active .middle {
  stroke-dashoffset: -71px;
}

.ham6.active .bottom {
  stroke-dashoffset: -132px;
}

.ham7 .top {
  stroke-dasharray: 40 82;
}

.ham7 .middle {
  stroke-dasharray: 40 111;
}

.ham7 .bottom {
  stroke-dasharray: 40 161;
}

.ham7.active .top {
  stroke-dasharray: 17 82;
  stroke-dashoffset: -62px;
}

.ham7.active .middle {
  stroke-dashoffset: 23px;
}

.ham7.active .bottom {
  stroke-dashoffset: -83px;
}

.ham8 .top {
  stroke-dasharray: 40 160;
}

.ham8 .middle {
  stroke-dasharray: 40 142;
  transform-origin: 50%;
  transition: transform 400ms;
}

.ham8 .bottom {
  stroke-dasharray: 40 85;
  transform-origin: 50%;
  transition: transform 400ms, stroke-dashoffset 400ms;
}

.ham8.active .top {
  stroke-dashoffset: -64px;
}

.ham8.active .middle {
  transform: rotate(90deg);
}

.ham8.active .bottom {
  stroke-dashoffset: -64px;
}

svg#menubutton.active {
  position: fixed;
  right: 14px;
  z-index: 2;
}

.slick-slider {
  position: inherit;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
  padding: 0;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-evenly;
}
.slick-list .slick-track {
  margin: 0;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
}

.slick-dots {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  justify-content: center;
  margin: -8px auto 0;
  bottom: unset;
}
.slick-dots.slick-dots-bottom {
  bottom: 50px;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 5px; /* Add margin between the dots */
  padding: 0;
  cursor: pointer;
  border-radius: 50%;
}
.slick-dots li button {
  display: block;
  width: 100%;
  height: 8px;
  padding: 0px;
  color: transparent;
  font-size: 12px;
  background-color: rgb(234, 236, 239) !important;
  border: 0px;
  border-radius: 8px;
  outline: none;
  cursor: pointer;
  transition: all 0.5s ease 0s;
}
.slick-dots li.slick-active button {
  background-color: #FFBE18 !important;
  background: #FFBE18 !important;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}
.slick-list {
  min-height: 468px;
}

.centered-carousel {
  display: flex;
  justify-content: center;
  align-items: center;
}
.centered-carousel .center-slide {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.pagination-wrap {
  margin-top: 2rem;
}

.pagination {
  --bs-pagination-bg: #ffffff;
  --bs-pagination-color: $secondary;
  --bs-pagination-border-width: 1px;
  --bs-pagination-border-color: transparant;
  --bs-pagination-hover-color: $secondary;
  --bs-pagination-hover-bg: #e9ecef;
  --bs-pagination-hover-border-color: transparant;
  --bs-pagination-focus-color: white;
  --bs-pagination-focus-bg: $primary;
  --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(175, 249, 4, 0.2);
  --bs-pagination-active-color: #000000;
  --bs-pagination-active-bg: rgb(255, 255, 255);
  --bs-pagination-active-border-color: $primary;
  --bs-pagination-disabled-color: #fff;
  --bs-pagination-disabled-bg: $primary;
  --bs-pagination-disabled-border-color: $primary;
}
.pagination .next {
  padding: 6px 24px;
}

.page-item.active .page-link {
  background-color: #FFBE18;
  border-color: #FFBE18;
  color: #181818;
}
.page-item.active .page-link:focus, .page-item.active .page-link:active, .page-item.active .page-link:hover {
  background: #181818;
  color: #FFBE18;
  box-shadow: 0 0 5px 1px rgba(255, 190, 24, 0.6) !important;
}
.page-item.disabled .page-link {
  background-color: #181818;
  border: 1px solid #FFBE18;
  color: #FFBE18;
}
.page-item .page-link {
  border-radius: 0.5rem;
  margin: 3px;
  width: 30px;
  height: 30px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
  background-color: #181818;
  color: #FFBE18;
  border: #000000;
}
.page-item .page-link:focus, .page-item .page-link:active, .page-item .page-link:hover {
  background: #181818;
  color: #FFBE18;
  box-shadow: 0 0 5px 1px rgba(255, 190, 24, 0.6) !important;
}
.page-item:hover {
  color: #181818;
}
.page-item:last-child .page-link, .page-item:first-child .page-link {
  border-radius: 0.5rem;
  background: #181818;
  color: #FFBE18;
  margin-left: 0px;
}

*, html {
  box-sizing: border-box;
}

body {
  font-family: "Open Sans", sans-serif;
  background-color: #181818;
  margin: 0;
  padding: 0;
  color: #FFF;
}

a {
  color: #FFBE18;
  text-decoration: none;
}
a:hover, a:focus, a:active {
  color: #FFBE18;
  text-decoration: underline;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Work Sans", sans-serif;
}

.skiptranslate {
  display: none;
}

img {
  max-width: 100%;
}

ul.ant-menu {
  border-radius: 8px;
  border: none;
}

.ant-checkbox .ant-checkbox-inner:after {
  border-color: #000 !important;
}

.ant-menu-title-content a:hover {
  text-decoration: none;
}

@media screen and (max-width: 767px) {
  body,
  html,
  * {
    font-size: 14px;
  }
}
#admin-body {
  height: 100vh;
  overflow: hidden;
}
#admin-body a {
  text-decoration: none;
}

.admin-title {
  background-color: #181818;
  padding: 1rem 0;
  color: #fff;
}
.admin-title h4 {
  margin: 0 0 0 20px;
  text-transform: uppercase;
}
.admin-title h3 {
  margin: 0;
  text-transform: uppercase;
}

.title-bar {
  background-color: #111925;
  padding: 2px 0;
  margin-bottom: 1rem;
  display: none;
}
.title-bar h4 {
  color: #fff;
  margin: 0;
  font-size: 18px;
  font-weight: normal;
}

.admin-card {
  border-radius: 10px;
  color: #ffffff;
  margin-bottom: 25px;
  transition: all 0.4s ease-in-out;
}
.admin-card .card-inner {
  border-radius: 10px;
  color: #142129;
  position: relative;
  font-size: 16px;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
  border: 3px solid rgba(255, 255, 255, 0.5);
  box-shadow: -1px -1px 11px 2px rgba(255, 190, 24, 0.51);
  -webkit-box-shadow: -1px -1px 11px 2px rgba(255, 190, 24, 0.51);
  -moz-box-shadow: -1px -1px 11px 2px rgba(255, 190, 24, 0.51);
  overflow: hidden;
  transition: all 0.4s ease;
  cursor: pointer;
  padding: 16px 0;
  gap: 10px;
  width: 100%;
}
.admin-card .card-inner .icon {
  padding-top: 10px;
}
.admin-card .card-inner .icon img {
  width: 60px;
  transition: all 0.5s ease;
}
.admin-card .card-inner:hover .icon img {
  transform: scale(1.2);
}
.admin-card .card-inner .value {
  min-width: 100%;
  position: relative;
  min-height: 39px;
  padding: 9px 20px;
  background: #FFBE18;
  text-align: center;
}
.admin-card .card-inner .value::after {
  content: "";
  width: 100%;
  position: absolute;
  height: 50%;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.15);
}
.admin-card .card-inner h2 {
  font-weight: 600;
  margin: 0;
  font-size: 1.1rem;
  border-radius: 0px;
  color: #000000;
}
.admin-card .card-inner p {
  font-size: 1.1rem;
  text-align: center;
  text-transform: capitalize !important;
  color: #fff;
  margin: 10px 0;
}
.admin-card .card-inner h6 {
  color: #000000;
  font-weight: 400;
  position: absolute;
  top: 25px;
  right: 16px;
  transform: translate(0%, -50%);
}
.admin-card .card-inner.blue, .admin-card .card-inner.red, .admin-card .card-inner.green, .admin-card .card-inner.brown {
  background: #000000;
}
.admin-card .card-inner::after {
  content: "";
  width: 100%;
  position: absolute;
  height: 50%;
  top: 0;
  left: 0;
  background-color: rgba(124, 124, 124, 0.15);
}

.header-admin {
  border-bottom: 1px solid rgba(255, 255, 255, 0.49);
  padding: 20px 0;
}
.header-admin .navbar-brand img {
  width: 150px !important;
}
.header-admin .navbar-nav .nav-link {
  padding: 10px 15px;
  font-size: 15px;
}
.header-admin .admin_header_right {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.header-admin .admin_header_right li {
  margin-left: 20px;
}
.header-admin .admin_header_right li a {
  color: #FFBE18;
}
.header-admin .admin_header_right li .admin_logged_in_user {
  display: flex;
  align-items: center;
}
.header-admin .admin_header_right li .admin_logged_in_user span {
  width: 40px;
  height: 40px;
  background-color: #FFBE18;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: #000000;
  margin-right: 10px;
}
.header-admin .admin_header_right li .admin_logged_in_user h4 {
  margin: 0;
  font-size: 16px;
  color: #fff;
}
.header-admin .admin_header_right li .admin_logged_in_user p {
  margin: 0;
  font-size: 13px;
  color: #666;
}

.admin-navbar {
  display: none !important;
}

.admin_login_wrap {
  background-size: cover;
  background-attachment: fixed;
  min-height: 100vh;
  background-color: rgb(243, 244, 246);
  display: flex;
  align-items: center;
  justify-content: center;
}
.admin_login_wrap .login_box {
  width: 450px;
  margin: auto;
}
.admin_login_wrap .login_box .login_box_head {
  background-color: #111925;
  border-radius: 6px 6px 0 0;
  padding: 20px;
  text-align: center;
}
.admin_login_wrap .login_box .login_box_head img {
  width: 80px;
}
.admin_login_wrap .login_box .login_box_head h4 {
  margin: 20px 0 0 0;
  font-size: 20px;
  color: #fff;
}
.admin_login_wrap .login_box .login_box_head p {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.7);
  font-weight: 300;
  margin-bottom: 0;
}
.admin_login_wrap .login_box .login_box_body {
  background-color: #333;
  border-radius: 0 0 6px 6px;
  padding: 40px;
  border: 1px solid #e5e7eb;
}
.admin_login_wrap .login_box .login_box_body input {
  color: #000;
}

.verify {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}
.verify .Character {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  background-color: #FFBE18;
  width: 40%;
  min-height: 40px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  justify-content: center;
  color: #000000;
  text-shadow: 1px solid black;
  letter-spacing: 0.6rem;
  font-style: italic;
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
}
.verify .form-group {
  width: 60%;
}

.admin_card_accordian {
  background-color: transparent;
  border: none;
}
.admin_card_accordian .accordion-item {
  border: none;
  background-color: transparent;
}
.admin_card_accordian .accordion-item .accordion-header .accordion-button {
  background: rgb(86, 83, 61);
  background: linear-gradient(90deg, rgb(86, 83, 61) 0%, rgb(17, 25, 37) 100%);
  padding: 1.5rem;
  color: #fff;
  font-weight: 400;
  font-size: 20px;
}
.admin_card_accordian .accordion-item .accordion-header .accordion-button:focus {
  box-shadow: none;
}
.admin_card_accordian .accordion-item .accordion-header .accordion-button::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23FFFFFF%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
}
.admin_card_accordian .accordion-item .accordion-header .accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23FFFFFF%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
}
.admin_card_accordian .accordion-item .accordion-body {
  background-color: transparent;
  border: none;
  padding: 20px 0;
}

.admin-content .nav-pills {
  margin-top: 1rem;
}
.admin-content .nav-pills .nav-item .nav-link {
  color: rgba(255, 255, 255, 0.7);
}
.admin-content .nav-pills .nav-item .nav-link.active {
  background-color: #FFBE18;
  color: #000;
}

.header-admin {
  background-color: #000;
}

.input-icon {
  position: relative;
}
.input-icon .icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 16px;
  cursor: pointer;
}
.input-icon .icon svg {
  width: 20px;
  cursor: pointer;
  color: #FFBE18;
}
.input-icon .icon-right {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 16px;
}
.input-icon .icon-right svg {
  width: 20px;
  cursor: pointer;
  color: #FFBE18;
}

.inline-action {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style-type: none;
  justify-content: center;
}
.inline-action li:last-child a {
  border-right: none;
}
.inline-action li a {
  text-transform: uppercase;
  font-size: 13px;
  color: #FFBE18;
  display: inline-block;
  border-right: 1px solid rgba(255, 255, 255, 0.7);
  padding: 0 8px;
}

.admin-wrap {
  display: flex;
}
.admin-wrap .admin-left {
  width: 15.5%;
  background-color: #111925;
  height: 100vh;
  overflow-y: scroll;
}
.admin-wrap .admin-left:hover::-webkit-scrollbar-thumb {
  display: block;
}
.admin-wrap .admin-left:hover::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
}
.admin-wrap .admin-left::-webkit-scrollbar {
  width: 4px;
}
.admin-wrap .admin-left::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 50px;
  display: none;
}
.admin-wrap .admin-left .admin_side_nav {
  margin: 0;
  padding: 15px 11px;
  list-style-type: none;
}
.admin-wrap .admin-left .admin_side_nav li:first-child a {
  border: none;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  overflow: hidden;
  transition: all 0.4s ease;
  background-color: transparent;
  text-decoration: none;
  border-radius: 5px;
  height: 125px;
  padding: 0;
  position: relative;
}
.admin-wrap .admin-left .admin_side_nav li:first-child a img {
  width: 150px;
}
.admin-wrap .admin-left .admin_side_nav li:first-child a:hover {
  background-color: transparent;
}
.admin-wrap .admin-left .admin_side_nav li:first-child a::after {
  display: none;
}
.admin-wrap .admin-left .admin_side_nav li:first-child a:hover, .admin-wrap .admin-left .admin_side_nav li:first-child a.active, .admin-wrap .admin-left .admin_side_nav li:first-child a:focus {
  border: none;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  background-color: transparent;
}
.admin-wrap .admin-left .admin_side_nav li a {
  font-family: "Work Sans", sans-serif;
  font-size: 13px;
  color: rgba(255, 255, 255, 0.7);
  padding: 6px 16px;
  display: flex;
  align-items: center;
  border-radius: 50px;
  position: relative;
  border: 3px solid transparent;
}
.admin-wrap .admin-left .admin_side_nav li a::after {
  content: "";
  width: 100%;
  position: absolute;
  height: 100%;
  bottom: -24px;
  left: 0;
  background-color: rgba(0, 0, 0, 0.15);
  opacity: 0;
}
.admin-wrap .admin-left .admin_side_nav li a:hover, .admin-wrap .admin-left .admin_side_nav li a.active, .admin-wrap .admin-left .admin_side_nav li a:focus {
  border: 3px solid rgba(255, 255, 255, 0.5);
  box-shadow: -1px -1px 11px 2px rgba(255, 190, 24, 0.51);
  -webkit-box-shadow: -1px -1px 11px 2px rgba(255, 190, 24, 0.51);
  -moz-box-shadow: -1px -1px 11px 2px rgba(255, 190, 24, 0.51);
  overflow: hidden;
  transition: all 0.4s ease;
  background-color: #FFBE18;
  color: #000;
  text-decoration: none;
}
.admin-wrap .admin-left .admin_side_nav li a:hover::after, .admin-wrap .admin-left .admin_side_nav li a.active::after, .admin-wrap .admin-left .admin_side_nav li a:focus::after {
  opacity: 1;
}
.admin-wrap .admin-left .admin_side_nav li a i {
  line-height: unset;
  margin-right: 10px;
}
.admin-wrap .admin-right {
  width: 84.5% !important;
  background-color: #000000;
}
.admin-wrap .admin-right > .admin-content {
  overflow-y: scroll;
  height: calc(100vh - 69px);
  padding: 30px 30px 150px 30px;
}

.form-group .form-control {
  min-height: 60px;
  background-color: #161E26;
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: #FFF;
}
.form-group .form-select {
  min-height: 60px;
  background-color: #161E26;
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: #FFF;
}
.form-group input:active, .form-group input:focus, .form-group input:target, .form-group input:focus-visible,
.form-group select:active,
.form-group select:focus,
.form-group select:target,
.form-group select:focus-visible {
  box-shadow: none !important;
  outline: none !important;
  border: none;
  border-color: transparent !important;
}

.form_admin .card {
  background-color: #181818;
  border: transparent;
}
.form_admin .form-group {
  position: relative;
}
.form_admin .form-group label {
  color: #ffffff;
  font-weight: 500;
  font-size: 13px;
  margin-bottom: 3px;
}
.form_admin .form-group .form-control,
.form_admin .form-group .form-select {
  border: 1px solid rgba(255, 255, 255, 0.28);
  border-radius: 8px;
  background-color: black;
  min-height: 36px;
}
.form_admin .form-group .form-control:active, .form_admin .form-group .form-control:focus, .form_admin .form-group .form-control:target, .form_admin .form-group .form-control:focus-visible,
.form_admin .form-group .form-select:active,
.form_admin .form-group .form-select:focus,
.form_admin .form-group .form-select:target,
.form_admin .form-group .form-select:focus-visible {
  border-color: rgba(255, 190, 24, 0.6);
  border: 1px solid rgba(255, 190, 24, 0.6);
  box-shadow: 0 0 5px 1px rgba(255, 190, 24, 0.6) !important;
}
.form_admin .form-group .form-control::placeholder,
.form_admin .form-group .form-select::placeholder {
  color: rgb(255, 255, 255);
}
.form_admin .form-group .form-control option:hover,
.form_admin .form-group .form-select option:hover {
  background-color: #00FF00;
}
.form_admin .form-group .form-control.disabled,
.form_admin .form-group .form-select.disabled {
  background-color: #000000;
  border: 1px solid rgba(255, 255, 255, 0.28);
  opacity: 1;
}
.form_admin .form-group .form-control.disabled::placeholder,
.form_admin .form-group .form-select.disabled::placeholder {
  color: black;
}
.form_admin .form-group input:active, .form_admin .form-group input:focus, .form_admin .form-group input:target, .form_admin .form-group input:focus-visible,
.form_admin .form-group select:active,
.form_admin .form-group select:focus,
.form_admin .form-group select:target,
.form_admin .form-group select:focus-visible {
  box-shadow: none !important;
  outline: none !important;
  border: none;
  border-color: transparent !important;
}

.user-card {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  flex-wrap: wrap;
  align-items: center;
}
.user-card .front,
.user-card .back {
  width: 48%;
  height: auto;
  border: 1px solid #ccc;
  display: flex;
  margin: auto;
  border-radius: 8px;
}
.user-card .front img,
.user-card .back img {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 8px;
}

.progress .progress-bar,
.progress-stacked .progress-bar {
  background-color: #FFBE18;
}

.admin-inputwrap {
  background: rgb(180, 166, 102);
  background: linear-gradient(80deg, rgb(190, 169, 75) 0%, rgb(143, 140, 114) 50%, rgba(255, 255, 255, 0) 100%);
  padding: 2px;
  border-radius: 12px;
}
.admin-inputwrap .inner {
  background-color: #ffffff;
  border-radius: 10px;
  overflow: hidden;
  height: 40px;
  padding: 8px;
}

.admin-card-title {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 4px;
  color: #FFBE18;
}
.admin-card-title span {
  color: #ffffff;
}

@media screen and (max-width: 767px) {
  .user-card .front,
  .user-card .back {
    width: 100%;
  }
  .admin-card-title {
    font-size: 16px;
  }
  .admin-card-title span {
    font-size: 16px;
  }
  .admin-navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    z-index: 10;
    background-color: white;
  }
  .admin-navbar .admin_logged_in_user {
    display: flex;
    align-items: center;
  }
  .admin-navbar .admin_logged_in_user span {
    width: 40px;
    height: 40px;
    background-color: #FFBE18;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: #fff;
    margin-right: 10px;
  }
  .admin-navbar .admin_logged_in_user h4 {
    margin: 0;
    font-size: 16px;
    color: #000;
  }
  .admin-navbar .admin_logged_in_user p {
    margin: 0;
    font-size: 13px;
    color: #666;
  }
  .admin-wrap {
    display: flex;
  }
  .admin-wrap .admin-left {
    width: 280px;
    position: absolute;
    transform: translateX(-100%);
    transition: all 0.5s ease;
    z-index: 9;
  }
  .admin-wrap .admin-left.active {
    transform: translateX(0);
  }
  .admin-wrap .admin-left .admin_side_nav li a {
    font-size: 22px;
  }
  .admin-wrap .admin-left .admin_side_nav li a i {
    font-size: 18px;
  }
  .admin-wrap .admin-right {
    width: 100% !important;
  }
}
.network-list ul {
  list-style: none;
  padding: 0;
}
.network-list ul li.level-1 {
  padding-left: 0;
}
.network-list ul li.level-1 .tree-card-wrap .tree-card-right ul li .level span {
  background-color: rgba(255, 190, 24, 0.2);
  border-color: #FFBE18;
  color: #FFBE18;
}
.network-list ul li.level-2 {
  padding-left: 4%;
}
.network-list ul li.level-2 .tree-card-wrap .tree-card-right ul li .level span {
  background-color: rgba(69, 248, 129, 0.2);
  border-color: #45F882;
  color: #45F882;
}
.network-list ul li.level-3 {
  padding-left: 8%;
}
.network-list ul li.level-3 .tree-card-wrap .tree-card-right ul li .level span {
  background-color: rgba(19, 93, 231, 0.2);
  border-color: #135EE7;
  color: #135EE7;
}
.network-list ul li .tree-card-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  background: #151517;
  -webkit-backdrop-filter: blur(100px);
  backdrop-filter: blur(100px);
  border: 1px solid #313134;
  min-width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  position: relative;
}
.network-list ul li .tree-card-wrap:hover {
  border: 1px solid #FFBE18;
}
.network-list ul li .tree-card-wrap:hover .profile .info h5 {
  color: #FFBE18;
}
.network-list ul li .tree-card-wrap:hover .level span {
  background-color: #FFBE18;
  color: #000;
}
.network-list ul li .tree-card-wrap .tree-card-right {
  position: absolute;
  right: 0;
  width: 820px;
  height: 100%;
}
.network-list ul li .tree-card-wrap .tree-card-right ul {
  margin: 0;
  padding: 10px 0 0 0;
  list-style-type: none;
  display: flex;
  justify-content: space-between;
}
.network-list ul li .tree-card-wrap .tree-card-right ul li {
  flex: 1;
}
.network-list ul li .tree-card-wrap .tree-card-right ul li.total_members .tree-icon-info {
  justify-content: flex-end;
}
.network-list ul li .tree-card-wrap .tree-card-right ul li .tree-info span {
  font-size: 0.7rem;
  color: #7c7c7c;
}
.network-list ul li .tree-card-wrap .tree-card-right ul li .tree-info p {
  margin: 0;
  font-size: 0.9rem;
}
.network-list ul li .tree-card-wrap .tree-card-right ul li .tree-icon-info {
  display: flex;
  align-items: center;
}
.network-list ul li .tree-card-wrap .tree-card-right ul li .tree-icon-info p {
  margin: 0;
}
.network-list ul li .tree-card-wrap .tree-card-right ul li .tree-icon-info span {
  color: #FFBE18;
  margin: 0 10px;
}
.network-list ul li .tree-card-wrap .tree-card-right ul li .tree-icon-info .amount h5 {
  margin: 0;
  font-weight: 500;
  font-size: 1.1rem;
  white-space: nowrap;
}
.network-list ul li .tree-card-wrap .tree-card-right ul li .tree-icon-info .amount p {
  font-size: 0.8rem;
  color: #7c7c7c;
}
.network-list ul li .tree-card-wrap .tree-card-right ul li .level {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 10%;
}
.network-list ul li .tree-card-wrap .tree-card-right ul li .level p {
  margin: 0;
}
.network-list ul li .tree-card-wrap .tree-card-right ul li .level span {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #3e472b;
  color: #FFBE18;
  border-radius: 50%;
  border: 1px solid;
  margin-left: 10px;
  font-weight: bold;
  transition: all 0.5s ease;
  font-family: "Work Sans", sans-serif;
  font-size: 1.5rem;
}
.network-list ul li .tree-card-wrap .profile {
  display: flex;
  align-items: center;
  min-width: 25%;
}
.network-list ul li .tree-card-wrap .profile img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.network-list ul li .tree-card-wrap .profile .info {
  margin-left: 10px;
}
.network-list ul li .tree-card-wrap .profile .info h5 {
  margin: 0;
  font-weight: 500;
  font-size: 1.1rem;
  line-height: 1.25rem;
  margin-bottom: 2px;
  text-transform: capitalize;
}
.network-list ul li .tree-card-wrap .profile .info p {
  margin: 0;
  font-weight: 300;
  font-size: 0.8rem;
  color: #7c7c7c;
}

.progress-wrap {
  margin-top: -4px;
}

@media screen and (max-width: 767px) {
  .network-list ul li .tree-card-wrap {
    display: block;
  }
  .network-list ul li .tree-card-wrap .profile {
    width: 100%;
  }
  .network-list ul li .tree-card-wrap .tree-card-right {
    position: relative;
    width: 100%;
  }
  .network-list ul li .tree-card-wrap .tree-card-right ul li {
    flex: none;
  }
  .network-list ul li .tree-card-wrap .tree-card-right ul li .level {
    position: absolute;
    top: -44px;
    right: 0;
  }
}


