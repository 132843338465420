.wrapper {
    display: flex;
    width: 100%;
    background-color: rgb(241, 245, 249);
    height: 100vh;
    overflow: hidden;


    .left-side {

        height: 100vh;
        width: 280px;
        padding: 0;
        color: rgb(255, 255, 255);
        border-right: 1px solid #000000;
        background-color: #000;

        >a {
            border-right: 1px solid #434343;
            border-bottom: 1px solid #434343;
            display: block;
            padding: 10px 23px;
            border-bottom-right-radius: 30px;
            background-color: #1E1E1E;
            padding-left: 20px;
            text-align: center;
            img{
                width: 60px;
            }
        }

        .side-bar {
            border-right: 1px solid #434343;
            border-top: 1px solid #434343;
            border-top-right-radius: 30px;
            display: flex;
            flex-direction: column;
            height: 100%;
            list-style: none;
            margin-top: -1px;
            padding-left: 20px;
            padding-top: 23px;
            background-color: #1E1E1E;

            li {
                margin-bottom: 24px;

                a {
                    display: flex;
                    text-decoration: none;
                    align-items: center;

                    i {
                        font-size: 1.5rem;
                        color: white;
                        margin-right: .9rem;
                    }

                    span {
                        font-size: 0.875rem;
                        color: white;
                    }

                    &.active,
                    &:hover {
                        border-right: 3px solid $primary;

                        i,
                        span {

                            color: $primary;
                        }
                    }
                }
            }
        }
    }

    .right-side {
        width: 100%;
        background-color: #000;

        .right-side-contents {
            width: 100%;
            padding: 0;

            .page-content {
                overflow-y: scroll;
                overflow-x: hidden;
                height: calc(100vh - 90px);
                padding: 0 28px 100px 28px;
                position: relative;
            }
        }



        .content-header {
            display: flex;
            justify-content: space-between;
            background: $black;
            box-shadow: 1px 1px 2px rgb(0 0 0 / 10%);
            align-items: center;
            padding: .8rem 2rem;
            border-bottom-left-radius: 20px;

            // .left {}

            .right {
                display: flex;
                justify-content: flex-end;

                .button {
                    margin-left: .5rem;
                }
            }

            h1 {
                font-size: 1.5rem;
                margin: 0;
                color: $primary;
                font-weight: bold;
            }
        }

        >.content {

            padding: 2rem;
            position: relative;


        }
    }

    #overlaymenu {
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
        position: absolute;
        z-index: 2;
        display: none;

        &.active {
            display: block;
        }
    }

}

.d-none {
    display: none;
}

#menubutton {
    display: none;
}

@media screen and (max-width: 767px) {
    .wrapper {
        display: block;

        .left-side {
            display: none;
            opacity: 0;
            /* Start with opacity set to 0 */

            &.active {
                position: absolute;
                top: 0 !important;
                left: 0 !important;
                z-index: 10;
                display: block;
                opacity: 1;
                /* Transition the opacity to 1 for a fade-in effect */
                transition: opacity 0.3s ease;
                /* Add a smooth transition */
            }
        }


        .right-side {
            padding-left: 0;

            >.content {
                padding: 1rem;
            }

            .content-header {
                flex-direction: column;
                align-items: flex-start;
                padding: 0.5rem 1rem;

                h1 {
                    font-weight: 600;
                    font-size: 1.2rem;
                    margin-bottom: .3rem;

                }


            }
        }
    }

    #menubutton {
        display: block;
    }

}