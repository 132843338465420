.network-list {
    ul {
        list-style: none;
        padding: 0;

        li {
            &.level-1 {
                padding-left: 0;

                .tree-card-wrap {
                    .tree-card-right {
                        ul {
                            li {
                                .level {
                                    span {
                                        background-color: rgba(255, 190, 24, 0.2);
                                        border-color: $primary;
                                        color: $primary;
                                    }
                                }
                            }
                        }

                    }
                }

            }

            &.level-2 {
                padding-left: 4%;

                .tree-card-wrap {
                    .tree-card-right {
                        ul {
                            li {
                                .level {
                                    span {
                                        background-color: rgba(69, 248, 129, 0.2);
                                        border-color: #45F882;
                                        color: #45F882;
                                    }
                                }
                            }
                        }

                    }
                }
            }

            &.level-3 {
                padding-left: 8%;

                .tree-card-wrap {
                    .tree-card-right {
                        ul {
                            li {
                                .level {
                                    span {
                                        background-color: rgba(19, 93, 231, 0.2);
                                        border-color: #135EE7;
                                        color: #135EE7;

                                    }
                                }
                            }
                        }

                    }
                }
            }


            .tree-card-wrap {
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-radius: 8px;
                background: #151517;
                -webkit-backdrop-filter: blur(100px);
                backdrop-filter: blur(100px);
                border: 1px solid #313134;
                min-width: 100%;
                padding: 10px;
                margin-bottom: 10px;
                position: relative;


                &:hover {
                    border: 1px solid $primary;

                    .profile {
                        .info {
                            h5 {
                                color: $primary;
                            }
                        }
                    }

                    .level {
                        span {
                            background-color: $primary;
                            color: #000;
                        }
                    }
                }


                .tree-card-right {
                    position: absolute;
                    right: 0;
                    width: 820px;
                    height: 100%;

                    ul {
                        margin: 0;
                        padding: 10px 0 0 0;
                        list-style-type: none;
                        display: flex;
                        justify-content: space-between;

                        li {
                            flex: 1;

                            &.total_members {
                                .tree-icon-info {
                                    justify-content: flex-end;
                                }
                            }

                            .tree-info {
                                span {
                                    font-size: 0.7rem;
                                    color: #7c7c7c;
                                }

                                p {
                                    margin: 0;
                                    font-size: .9rem;
                                }
                            }

                            .tree-icon-info {
                                display: flex;
                                align-items: center;

                                p {
                                    margin: 0;
                                }

                                span {
                                    color: $primary;
                                    margin: 0 10px;
                                }

                                .amount {
                                    h5 {
                                        margin: 0;
                                        font-weight: 500;
                                        font-size: 1.1rem;
                                        white-space: nowrap;
                                    }

                                    p {
                                        font-size: .8rem;
                                        color: #7c7c7c;
                                    }
                                }
                            }

                            .level {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                min-width: 10%;

                                p {
                                    margin: 0;
                                }

                                span {
                                    width: 40px;
                                    height: 40px;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    text-align: center;
                                    background-color: #3e472b;
                                    color: $primary;
                                    border-radius: 50%;
                                    border: 1px solid;
                                    margin-left: 10px;
                                    font-weight: bold;
                                    transition: all .5s ease;
                                    font-family: $font-secondary;
                                    font-size: 1.5rem;
                                }
                            }
                        }
                    }

                }

                .profile {
                    display: flex;
                    align-items: center;
                    min-width: 25%;


                    img {
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                    }


                    .info {
                        margin-left: 10px;

                        h5 {
                            margin: 0;
                            font-weight: 500;
                            font-size: 1.1rem;
                            line-height: 1.25rem;
                            margin-bottom: 2px;
                            text-transform: capitalize;
                        }

                        p {
                            margin: 0;
                            font-weight: 300;
                            font-size: .8rem;
                            color: #7c7c7c;
                        }
                    }
                }





            }

        }
    }
}

.progress-wrap {
    margin-top: -4px;
}

@media screen and (max-width: 767px) {
    .network-list {
        ul {
            li {
                .tree-card-wrap {
                    display: block;

                    .profile {
                        width: 100%;
                    }

                    .tree-card-right {
                        position: relative;
                        width: 100%;

                        ul {
                            li {
                                flex: none;

                                .level {
                                    position: absolute;
                                    top: -44px;
                                    right: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}