//colors
$primary: #FFBE18;
$secondary: #181818;


$black: #000000;

$litegray: #8a95a3;

$lite: #F0F2F5;

$white:#ffffff;

$danger: #BB0616;

$radius: .5rem;

$font-primary: 'Open Sans', sans-serif;
$font-secondary: 'Work Sans', sans-serif;